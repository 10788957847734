import Image from 'next/image';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'next-i18next';
import Select from "react-select";
import Link from 'next/link';
import defaultImage from "../../../public/favicon-48x48.png";
import { ShimmerCategoryItem } from "react-shimmer-effects";

// ethereum config
import { withRouter } from 'next/router';
// API helper
import { apiHelperBlockchain } from "../../../helper/apiHelper";
// styles
import styles from "./TopCollection.module.scss";
// components
import dynamic from 'next/dynamic';
const NotFoundSection = dynamic(() => import('../../../components/NotFoundSection'))


// img
import verified from "../../../public/tick.png";
class TopCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_no: 1,
            page_size: 9,
            collectionList: [],
            nftLoader: false,
            clicked_viewMore: true,

            // network
            network: { value: 1, label: <><Image src={"/img/Avalanche_logo.png"} alt="avalanche" width={16} height={16} /><span>Avalanche</span></> },
            networkListDropdown: [
                { value: 1, label: <><Image src={"/img/Avalanche_logo.png"} alt='avalanche' width={16} height={16} /><span>Avalanche</span></> },
                // { value: 2, label: <><Image src={nexaImg} alt='nexa' width={16} height={16} /><span>Nexa</span></>},
                // { value: 2, label: <><Image src={nexaImg} alt={this.props.t('common:polygon')} width={16} height={16} /><span>{this.props.t('common:polygon')}</span></> },
            ]
        }
    }

    componentDidMount = async () => {
        await this.getAllCollection();
    }

    getAllCollection = async () => {

        if (this.state.page_no === 1) { this.setState({ nftLoader: true }); }

        let cList = this.state.collectionList;
        let cList1 = [];

        await apiHelperBlockchain('get-hot-collections?page=' + this.state.page_no + "&size=" + this.state.page_size + "&chainIndex=" + this.state.network.value, 'GET').then(resp => {
            this.setState({ nftLoader: false });
            if (resp.data.status) {
                cList1 = resp.data.collections;

                let fArray = cList.concat(cList1);
                this.setState({ collectionList: fArray }, () => {
                    if (this.state.collectionList.length === resp.data.totalCollections) {
                        this.setState({ clicked_viewMore: false });
                    }
                });
            }
        }).catch(error => console.error(`Error: ${error}`));
    }

    loadMoreCollection = () => {
        this.setState({ page_no: this.state.page_no + 1 }, () => { this.getAllCollection(); });
    }

    handleChangeBlockchain = (opt) => {
        this.setState({ network: opt, page_no: 1, size: 9, collectionList: [] }, () => {
            this.getAllCollection();
        });
    };

    render() {
        const { t } = this.props;
        let i = 1;
        return (
            <div className={`section ${styles.top_collections}`}>
                <Container>
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                        <h2 className="h3 mb-0">{t('common:all_collections')}</h2>
                        <div className={`${styles.selectMenu} selectMenu`}>
                            <Select
                                inputId="networklist"
                                value={this.state.network}
                                onChange={this.handleChangeBlockchain}
                                options={this.state.networkListDropdown}
                                classNamePrefix="select__Inner"
                                placeholder={t('common:select')}
                                isSearchable={false}
                                instanceId="blockchain"
                            />
                        </div>
                    </div>
                    <div className={styles.collection_list}>
                        {this.state.nftLoader ?
                            <Row> 
                                <Col xl={4} md={6}>
                                    <ShimmerCategoryItem
                                        hasImage
                                        imageType="circular"
                                        imageWidth={50}
                                        imageHeight={50}
                                        title
                                    />
                                </Col>
                                <Col xl={4} md={6}>
                                    <ShimmerCategoryItem
                                        hasImage
                                        imageType="circular"
                                        imageWidth={50}
                                        imageHeight={50}
                                        title
                                    />
                                </Col>
                                <Col xl={4} md={6}>
                                    <ShimmerCategoryItem
                                        hasImage
                                        imageType="circular"
                                        imageWidth={50}
                                        imageHeight={50}
                                        title
                                    />
                                </Col>
                            </Row>
                            :
                            <Row>
                                {this.state.collectionList && (this.state.collectionList).length > 0 ?
                                    this.state.collectionList.map((item, idx) => {
                                        return (
                                            <Col md={6} xl={4} key={idx}>
                                                <Link href={"/nft/collections/" + decodeURI(item.collection_slug)}>
                                                    <a className={`d-flex align-items-center justify-content-between ${styles.collection_list_wrap}`}>
                                                        <div className="d-flex align-items-center">
                                                            <span className={styles.count}>{i++}</span>
                                                            <div className={`position-relative ${styles.card__avatar}`}>
                                                                {console.log("item.image_url",item.image_url)}
                                                                {item.image_url && item.image_url ?
                                                                    <Image src={item.image_url} alt={t('common:all_collections')} width={50} height={50} layout="responsive" loading='lazy' />
                                                                    : <Image src={defaultImage} alt={t('common:all_collections')} width={50} height={50} layout="responsive" loading='lazy' />}
                                                                <div className={styles.done}>
                                                                    <Image src={verified} alt={t('common:all_collections')} width="16" height="16" layout='responsive' loading='lazy' />
                                                                </div>
                                                            </div>
                                                            <div className={styles.card_body}>
                                                                <div className={styles.name}>
                                                                    {item.collection_name && item.collection_name.length > 20 ? item.collection_name.substring(0, 20) + "..." : item.collection_name}</div>
                                                                {item.floorPrice && <div className={`d-flex align-items-center  ${styles.content}`}>
                                                                    <span className={styles.price_lbl}>{t('common:floor_price')}: </span>
                                                                    <Image src={"/img/Avalanche_logo.png"} alt="avalanche" width={16} height={16} layout="responsive" />
                                                                    &nbsp; {parseFloat(item.floorPrice).toFixed(4)}
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        {/* <div className={styles.card_body}>
                                                            <div className={styles.content}>
                                                                 <span className={`d-block text-green ${styles.rate}`} onClick={() => alert('copied')}>Share</span> 
                                                                <span className={`d-block text-green ${styles.rate}`}>+189%</span> 
                                                                <div className="d-flex align-items-center">
                                                                    <svg className="me-1" width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.4999 6.17625L3.75102 0.0664062V4.50141L7.03317 5.96785L3.75102 4.50229V8.35277L7.4999 6.17637L7.50002 6.17641L7.49998 6.17633L7.50002 6.17629L7.4999 6.17625ZM3.75002 4.50141V0.0664062L0.00161514 6.17625L0.00153542 6.17629L0.00157509 6.17633L0.00153542 6.17641L0.0016552 6.17637L3.75002 8.35277V4.50229L0.468295 5.96785L3.75002 4.50141ZM3.75002 9.05037V12.0638L-0.000976562 6.87437L3.75002 9.05037ZM3.75102 9.04985V12.0638L7.50002 6.87437L3.75102 9.04985Z" fill="#6B8CEF" />
                                            </svg>
                                                                </div>
                                                            </div>
                                                        </div>*/}
                                                    </a>
                                                </Link>
                                            </Col>
                                        )
                                    }) : <NotFoundSection type="collection-outside" />}
                            </Row>
                        }
                    </div>

                    {
                        this.state.clicked_viewMore ? (this.state.page_no === 1 && this.state.collectionList.length < this.state.page_size) ?
                            null :
                            <div className="text-center" onClick={this.loadMoreCollection}>
                                <div className={`btn buttonStroke loadmoreBtn`}>
                                    {t('common:view_more')}
                                </div>
                            </div> : null
                    }
                </Container >
            </div >
        )
    }
}
export default withTranslation()(withRouter(TopCollection))