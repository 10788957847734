import React, { Component } from 'react';
import Select from 'react-select';
import { Accordion, Card, Col, Row, Form, InputGroup, Button, Dropdown } from 'react-bootstrap';
import { withTranslation } from 'next-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import Router from 'next/router';
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation, A11y } from "swiper";
import Image from "next/image";

// img
import allImg from "../../../public/img/all.png"

// To include the default styles
import 'react-rangeslider/lib/index.css';

// Styles
import styles from "./CategoryFilterDesktop.module.scss";

// function CustomToggle({ children, eventKey }) {
//     const [toggle, setToggle] = useState(false);
//     const decoratedOnClick = useAccordionButton(eventKey, () =>
//         setToggle(!toggle)
//     );
//     return (
//         <button
//             type="button"
//             onClick={decoratedOnClick}
//             className={`btn ${styles.filterBtn}`}
//         >
//             {children}
//             {toggle ?
//                 <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path fillRule="evenodd" clipRule="evenodd" d="M1.05752 1.05703C1.57822 0.536329 2.42244 0.536329 2.94313 1.05703L10.0003 8.11422L17.0575 1.05703C17.5782 0.536329 18.4224 0.536329 18.9431 1.05703C19.4638 1.57773 19.4638 2.42195 18.9431 2.94265L11.8859 9.99984L18.9431 17.057C19.4638 17.5777 19.4638 18.4219 18.9431 18.9426C18.4224 19.4633 17.5782 19.4633 17.0575 18.9426L10.0003 11.8855L2.94314 18.9426C2.42244 19.4633 1.57822 19.4633 1.05752 18.9426C0.536818 18.4219 0.536818 17.5777 1.05752 17.057L8.11471 9.99984L1.05752 2.94265C0.536817 2.42195 0.536817 1.57773 1.05752 1.05703Z" fill="#fcfcfd" />
//                 </svg>
//                 :
//                 <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 11.6972C12.0001 11.4998 12.0585 11.3068 12.168 11.1425L17.2266 3.5547C17.6696 2.89015 17.1932 2 16.3945 2H3.60563C2.80693 2 2.33054 2.89015 2.77358 3.5547L7.83213 11.1425C7.94164 11.3068 8.00008 11.4998 8.00008 11.6972V17.5873C8.00008 18.3727 8.86403 18.8515 9.53008 18.4353L11.5301 17.1853C11.8225 17.0025 12.0001 16.682 12.0001 16.3373V11.6972ZM14.0001 12.3028C14.0001 12.1054 14.0585 11.9123 14.168 11.7481L18.8907 4.6641C20.2198 2.67044 18.7906 0 16.3945 0H3.60563C1.20955 0 -0.21963 2.67043 1.10948 4.6641L5.83213 11.7481C5.94164 11.9123 6.00008 12.1054 6.00008 12.3028V17.5873C6.00008 19.9436 8.59193 21.3801 10.5901 20.1313L12.5901 18.8813C13.4672 18.333 14.0001 17.3716 14.0001 16.3373V12.3028Z" fill="#fcfcfd" />
//                 </svg>
//             }
//         </button>
//     );
// }

class CategoryFilterDesktop extends Component {
    state = {
        recentOption: null,
        categoryList: [],


        //network with image
        network: { value: 0, label: <><Image src={allImg} alt={this.props.t('common:all')} width={16} height={16} /><span>{this.props.t('common:all')}</span></> },
        networkListDropdown: [
            { value: 0, label: <><Image src={allImg} alt={this.props.t('common:all')} width={16} height={16} /><span>{this.props.t('common:all')}</span></> },
            { value: 1, label: <><Image src={"/img/Avalanche_logo.png"} alt={this.props.t('common:ethereum')} width={16} height={16} /><span>{this.props.t('common:ethereum')}</span></> },
            // { value: 2, label: <><Image src={nexaImg} alt={this.props.t('common:polygon')} width={16} height={16} /><span>{this.props.t('common:polygon')}</span></> },
        ],

        //network
        networkList: [
            { id: 0, name: this.props.t('common:all'), img: <Image src={allImg} alt={this.props.t('common:all')} width={16} height={16} />, class: "active" },
            { id: 1, name: this.props.t('common:ethereum'), img: <Image src={"/img/Avalanche_logo.png"} alt={this.props.t('common:ethereum')} width={16} height={16} />, class: "" },
            // { id: 2, name: this.props.t('common:polygon'), img: <Image src={nexaImg} alt={this.props.t('common:polygon')} width={16} height={16} />, class: "" }
        ],

        //type
        type: { value: 0, label: this.props.t('common:all') },
        typeDropdown: [
            { value: 0, label: this.props.t('common:all') },
            { value: 1, label: this.props.t('common:single') },
            { value: 2, label: this.props.t('common:multiple') }
        ],

        //creator
        creator: { value: false, label: this.props.t('common:all') },
        creatorDropdown: [
            { value: false, label: this.props.t('common:all') },
            { value: true, label: this.props.t('common:verified') }
        ],

        sortListDropdown: [],
        sort: [
            { value: 1, label: this.props.t('common:price_low_high') }
        ],

        sliderValue: 0,
        cur_category: "0",
        cur_chain: 0,
        isOpen: this.props.isOpen,

        //Currency
        curCurrency: { value: 0, label: "USD" },
        currencyListMenu: [
            { value: 0, label: "USD" },
        ],

        //option
        option: { value: 0, label: this.props.t('common:all') },
        optionListDropdown: [
            { value: 0, label: this.props.t('common:all') },
            { value: 1, label: this.props.t('common:lazy_mint') }
            // { value: 1, label: this.props.t('common:') }
        ],

        // category for mobile
        cat: { value: 0, label: "❇️ " + this.props.t('common:all') },
        cList: [],

        // Price range
        min_value: "",
        max_value: "",
        priceLabel: "",

        isDrpOpen: true,
        minError: "",
        maxError: "",
        abDis: true
    };

    componentDidMount = () => {
        // let sortListDropdown = [];
        const { t } = this.props;
        if (Router.router && Router.router.asPath && Router.router.asPath === '/nft/sale') {
            this.setState({
                sort: { value: 2, label: t('common:nft_50_off') }
            })
            this.setState({
                sortListDropdown: [
                    { value: 1, label: t('common:nft_25_off') },
                    { value: 2, label: t('common:nft_50_off') },
                    { value: 3, label: t('common:nft_75_off') },
                ]
            })
        } else {
            this.setState({
                sortListDropdown: [
                    { value: 0, label: t('common:recently_listed') },
                    { value: 1, label: t('common:price_low_high') },
                    { value: -1, label: t('common:price_hign_low') },
                ]
            })
        }
        this.setState({ categoryList: this.props.categoryList, cList: this.props.cList });
        if (this.props.isFire === true) {
            this.setUrlValues();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props !== prevProps) {
            this.setState({ categoryList: this.props.categoryList, cList: this.props.cList });
            if (this.props.network !== prevProps.network) {
                this.setState({ priceLabel: "", min_value: "", max_value: "", curCurrency: { value: 0, label: "USD" } });
            }
        }
    }

    setUrlValues = () => {

        // chain
        if (this.props.chain && this.props.chain !== "") {
            this.setState({ cur_chain: parseInt(this.props.chain) });
        }

        // type
        if (this.props.type && this.props.type !== "") {
            let item_result = this.state.typeDropdown.find(x => x.value === parseInt(this.props.type));
            this.setState({ type: item_result });
        }

        // creator
        if (this.props.creator && this.props.creator !== "") {
            let creator_result = this.state.creatorDropdown.find(x => x.value === Boolean(this.props.creator));
            this.setState({ creator: creator_result });
        }

        // currency 
        if (this.props.min_price && parseInt(this.props.min_price) !== 0 && this.props.min_price !== "" && this.props.max_price && parseInt(this.props.max_price) !== 0 && this.props.max_price !== "") {
            this.setState({ min_value: parseFloat(this.props.min_price), max_value: parseFloat(this.props.max_price) });
            let currencyListMenu = [{ value: 0, label: "USD" }, { value: 1, label: "AVAX" }, { value: 2, label: "MATIC" }];
            let cur_result = currencyListMenu.find(x => x.value === parseInt(this.props.currency));
            this.setState({ curCurrency: cur_result });
        }
        this.props.applyAll(this.props.chain, this.props.type, this.props.creator, this.props.currency, this.props.min_price, this.props.max_price);

    }


    handleChangeBlockchain = (opt) => {
        this.setState({ network: opt }, () => {
            this.props.changeNetwork(opt.value);
            this.setCurrencyDropdownValues(opt.value);
            this.setState({ priceLabel: "", min_value: "", max_value: "", curCurrency: { value: 0, label: "USD" } });
            if (document.getElementById('drpdwn')) {
                document.getElementById('drpdwn').classList.add('d-none');
            }
        });
    };

    setCurrencyDropdownValues = (val) => {
        if (val === 0) {
            this.setState({ currencyListMenu: [{ value: 0, label: "USD" }] });
        }
        if (val === 1) {
            this.setState({ currencyListMenu: [{ value: 0, label: "USD" }, { value: 1, label: "AVAX" }] });
        }
        else if (val === 2) {
            this.setState({ currencyListMenu: [{ value: 0, label: "USD" }, { value: 2, label: "MATIC" }] });
        }
    }

    handleChangeType = (opt) => {
        this.setState({ type: opt }, () => {
            this.props.changeType(opt.value);
        });
    };

    handleChangeCreator = (opt) => {
        this.setState({ creator: opt }, () => {
            this.props.changeCreator(opt.value);
        });
    };

    lowerChainIdx = (idx) => {
        this.setState({ cur_chain: idx });
        this.setCurrencyDropdownValues(idx);
        this.props.changeChainIndex(idx);
        this.setState({ priceLabel: "", min_value: "", max_value: "", curCurrency: { value: 0, label: "USD" } });
        if (document.getElementById('drpdwn')) {
            document.getElementById('drpdwn').classList.add('d-none');
        }
    }

    changeCategory = (info) => {
        this.setState({ cur_category: info }, () => { this.props.changeCategory(info); });
    }

    handleChangeCategory = (info) => {
        this.setState({ cat: info }, () => { this.props.changeCategory(info.value); });
    }

    handleChangeCurrency = (item) => {
        this.setState({ curCurrency: item }, () => { this.setPriceLabel(); });
    }

    setPriceLabel = () => {
        if (this.state.min_value !== 0 && this.state.min_value !== "" && this.state.max_value !== 0 && this.state.max_value !== "") {
            let pl = this.state.curCurrency.label + "-" + parseFloat(this.state.min_value).toFixed(1) + "-" + parseFloat(this.state.max_value).toFixed(1);
            this.setState({ priceLabel: pl });
        }
    }

    setMinPrice = (e) => {

        let mp = e.target.value;
        this.setState({ min_value: mp });

        if (Math.sign(mp) === 0 || Math.sign(mp) === -1) {
            this.setState({ priceError: this.props.t('common:must_positive'), abDis: true });
        }
        if (parseFloat(mp) > parseFloat(this.state.max_value)) {
            this.setState({ priceError: this.props.t('common:price_val'), abDis: true });
        }
        else {
            this.setState({ priceError: "", abDis: false }, () => { this.setPriceLabel(); });
        }
    }

    setMaxPrice = (e) => {

        let mp = e.target.value;
        this.setState({ max_value: mp });
        if (Math.sign(mp) === 0 || Math.sign(mp) === -1) {
            this.setState({ priceError: this.props.t('common:must_positive'), abDis: true });
        }
        if (parseFloat(this.state.min_value) > parseFloat(mp)) {
            this.setState({ priceError: this.props.t('common:price_val'), abDis: true });
        }
        else {
            this.setState({ priceError: "", abDis: false }, () => { this.setPriceLabel(); });
        }
    }

    applyPrice = () => {
        this.props.applyPrice(this.state.min_value, this.state.max_value, this.state.curCurrency.value);
        document.getElementById('drpdwn').classList.add('d-none');
        // this.setState({ min_value : "", max_value : "", priceLabel : "", curCurrency: { value: 0, label: "USD" }});
    }

    handleChangeOption = (opt) => {
        this.setState({ option: opt }, () => { this.props.changeOptions(opt.value); });
    }

    resetPrice = () => {
        this.setState({ priceLabel: "", min_value: "", max_value: "", curCurrency: { value: 0, label: "USD" } }, () => {
            this.props.applyPrice(0, 0, this.state.curCurrency.value);
        });
    }

    removeClassFromDropdown = () => {
        if (document.getElementById("drpdwn") && document.getElementById("drpdwn").classList.contains("d-none")) {
            document.getElementById('drpdwn').classList.remove('d-none')
        }
    }

    handleSorting = (sort) => {
        this.setState({ sort }, () => {
            this.props.changePriceSort(sort.value);
        });
    };

    render() {
        const { t } = this.props;

        return (
            <Accordion className={styles.accordion} activeKey={this.state.isOpen ? 0 : null}>
                <Card className="border-0 bg-transparent">
                    <Card.Header>
                        <Row className="align-items-center justify-content-between">
                            <Col sm={4} lg={6} xxl={8}>
                                <div className='selectMenu d-block d-lg-none mt-3 mt-sm-0 mb-3 mb-sm-0'>
                                    {this.props.category ?
                                        <Select
                                            inputId="network"
                                            value={this.state.network}
                                            onChange={this.handleChangeBlockchain}
                                            options={this.state.networkListDropdown}
                                            classNamePrefix="select__Inner"
                                            placeholder={t('common:select')}
                                            isSearchable={false}
                                        />
                                        :
                                        <Select
                                            inputId="category"
                                            value={this.state.cat}
                                            onChange={this.handleChangeCategory}
                                            options={this.state.cList}
                                            classNamePrefix="select__Inner"
                                            placeholder={t('common:select')}
                                            isSearchable={false}
                                        />}
                                </div>

                                <div className='position-relative tabSlider d-lg-flex d-none'>
                                    {(this.state.networkList.length > 0 || this.state.categoryList.length > 0) &&
                                    <Swiper dir={this.props.currLocale === "ar" ? "rtl" : "ltr"}
                                            navigation={{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}}
                                            modules={[Navigation, A11y]}
                                            slidesPerView={'auto'}
                                            observer={'true'}
                                            observeParents={'true'}
                                            shouldSwiperUpdate={'true'}
                                            rebuildOnUpdate={'true'}
                                            direction={'horizontal'}
                                            resizeReInit={'true'}
                                            className={`mySwiper p-0 tabSwiper ${this.props.category ? styles.catClass : ""}`}
                                              on={{
                                                resize: function (swiper) {
                                                  swiper.update();
                                                }
                                              }}
                                              onSlideChange={(swiper) => {
                                                swiper.update();
                                              }}
                                            >

                                        {this.props.category ?
                                            <>
                                                {this.state.networkList.map((item, idx) => {
                                                    return (
                                                        <SwiperSlide key={idx}
                                                                     className={this.state.cur_chain === item.id ? "active" : ""}
                                                                     onClick={() => this.lowerChainIdx(item.id)}> {item.img} {item.name}
                                                        </SwiperSlide>
                                                    )
                                                })}
                                            </>
                                            :
                                            <>
                                                <SwiperSlide className={this.state.cur_category === "0" ? "active" : ""} onClick={() => this.changeCategory("0")}>{`❇️ ${t('common:all_items')}`}</SwiperSlide>
                                                {/* {this.state.categoryList && this.state.categoryList.map((info, i) => {
                                                    return (<SwiperSlide key={i} className={this.state.cur_category === info._id ? "active" : ""} onClick={() => this.changeCategory(info._id)}>{info.category_img + " " + (this.props.currLocale === "en" ? (info.category_name).charAt(0).toUpperCase() + (info.category_name).slice(1) : info.category_name_arabic)}</SwiperSlide>)
                                                })} */}
                                                 {this.state.categoryList && this.state.categoryList.map((info, i) => {
                                                    return (<SwiperSlide key={i} className={this.state.cur_category === info._id ? "active" : ""} onClick={() => this.changeCategory(info._id)}>
                                                        <span className={styles.imgs}>
                                                            <Image src={info.category_img_url} alt={info.category_name} width={20} height={20} layout="responsive" loading="lazy"  /> 
                                                        </span>
                                                        { this.props.currLocale === "en" ? (info.category_name).charAt(0).toUpperCase() + (info.category_name).slice(1): info.category_name_arabic}

                                                        {/* {info.category_img + " " + (this.props.currLocale === "en" ? (info.category_name).charAt(0).toUpperCase() + (info.category_name).slice(1) : info.category_name_arabic)} */}
                                                        
                                                        </SwiperSlide>)
                                                })}
                                            </>
                                        }

                                    </Swiper>
                                    }
                                    <div className="swiper-button-prev d-none"></div>
                                    <div className="swiper-button-next d-none"></div>
                                </div>
                            </Col>
                            <Col sm={8} lg={6} xxl={4}>
                                <div className="justify-content-end d-sm-flex">
                                    {this.props.isSortingShow && this.props.isSortingShow === true ?
                                        <div className={`selectMenu ${styles.selectMenu}`}>
                                            <Select
                                                inputId="sort"
                                                value={this.state.sort}
                                                onChange={this.handleSorting}
                                                options={this.state.sortListDropdown}
                                                classNamePrefix="select__Inner"
                                                placeholder={t('common:select')}
                                                isSearchable={false}
                                            />
                                        </div>
                                        : null}

                                    <Button
                                        type="button"
                                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                        className={`btn ${styles.filterBtn}`}>
                                        {t('common:filter')}
                                        {this.state.isOpen ?
                                            <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M1.05752 1.05703C1.57822 0.536329 2.42244 0.536329 2.94313 1.05703L10.0003 8.11422L17.0575 1.05703C17.5782 0.536329 18.4224 0.536329 18.9431 1.05703C19.4638 1.57773 19.4638 2.42195 18.9431 2.94265L11.8859 9.99984L18.9431 17.057C19.4638 17.5777 19.4638 18.4219 18.9431 18.9426C18.4224 19.4633 17.5782 19.4633 17.0575 18.9426L10.0003 11.8855L2.94314 18.9426C2.42244 19.4633 1.57822 19.4633 1.05752 18.9426C0.536818 18.4219 0.536818 17.5777 1.05752 17.057L8.11471 9.99984L1.05752 2.94265C0.536817 2.42195 0.536817 1.57773 1.05752 1.05703Z" fill="#fcfcfd" />
                                            </svg>
                                            :
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 11.6972C12.0001 11.4998 12.0585 11.3068 12.168 11.1425L17.2266 3.5547C17.6696 2.89015 17.1932 2 16.3945 2H3.60563C2.80693 2 2.33054 2.89015 2.77358 3.5547L7.83213 11.1425C7.94164 11.3068 8.00008 11.4998 8.00008 11.6972V17.5873C8.00008 18.3727 8.86403 18.8515 9.53008 18.4353L11.5301 17.1853C11.8225 17.0025 12.0001 16.682 12.0001 16.3373V11.6972ZM14.0001 12.3028C14.0001 12.1054 14.0585 11.9123 14.168 11.7481L18.8907 4.6641C20.2198 2.67044 18.7906 0 16.3945 0H3.60563C1.20955 0 -0.21963 2.67043 1.10948 4.6641L5.83213 11.7481C5.94164 11.9123 6.00008 12.1054 6.00008 12.3028V17.5873C6.00008 19.9436 8.59193 21.3801 10.5901 20.1313L12.5901 18.8813C13.4672 18.333 14.0001 17.3716 14.0001 16.3373V12.3028Z" fill="#fcfcfd" />
                                            </svg>
                                        }
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Accordion.Collapse eventKey={0}>
                        <Card.Body className="p-0">
                            <Row>
                                <Col md={3} className="gx-4">
                                    <div className="mb-3 mb-md-0">
                                        {this.props.indexFilter ?
                                            <>
                                                <label className="lbl">{t('common:blockchain')}</label>
                                                <div className="selectMenu">
                                                    <Select
                                                        inputId="blockchain"
                                                        value={this.state.network}
                                                        onChange={this.handleChangeBlockchain}
                                                        options={this.state.networkListDropdown}
                                                        classNamePrefix="select__Inner"
                                                        placeholder={t('common:select')}
                                                        isSearchable={false}
                                                        instanceId="blockchain"
                                                    />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <label className="lbl">{t('common:option')}</label>
                                                <div className="selectMenu">
                                                    <Select
                                                        inputId="option"
                                                        value={this.state.option}
                                                        onChange={this.handleChangeOption}
                                                        options={this.state.optionListDropdown}
                                                        classNamePrefix="select__Inner"
                                                        placeholder={t('common:select')}
                                                        isSearchable={false}
                                                        instanceId="likes"
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </Col>
                                <Col md={3} className="gx-4">
                                    <div className="mb-3 mb-md-0">
                                        <label className="lbl">{t('common:type')}</label>
                                        <div className="selectMenu">
                                            <Select
                                                inputId="type"
                                                value={this.state.type}
                                                onChange={this.handleChangeType}
                                                options={this.state.typeDropdown}
                                                classNamePrefix="select__Inner"
                                                placeholder={t('common:select')}
                                                isSearchable={false}
                                                instanceId="type"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} className="gx-4">
                                    <div className="mb-3 mb-md-0">
                                        <label className="lbl">{t('common:creator')}</label>
                                        <div className="selectMenu">
                                            <Select
                                                inputId="creator"
                                                value={this.state.creator}
                                                onChange={this.handleChangeCreator}
                                                options={this.state.creatorDropdown}
                                                classNamePrefix="select__Inner"
                                                placeholder={t('common:select')}
                                                isSearchable={false}
                                                instanceId="creator"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} className="gx-4">
                                    <div className="rangeSlider">
                                        <div className="lbl">{t('common:price_range')}</div>
                                        <Dropdown className={styles.rangeSlider}>
                                            <Dropdown.Toggle id="dropdown-basic" className='w-100 btn-transparent'>
                                                <Form.Control type="text" placeholder={t('common:select')} value={this.state.priceLabel} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu id="drpdwn">
                                                <div className={`form-group ${styles.inputGroup}`}>
                                                    <div className="selectMenu">
                                                        <Select
                                                            inputId="currency"
                                                            value={this.state.curCurrency}
                                                            onChange={this.handleChangeCurrency}
                                                            options={this.state.currencyListMenu}
                                                            classNamePrefix="select__Inner"
                                                            placeholder={t('common:price_range_placeholder')}
                                                            isSearchable={false}
                                                            instanceId="creator"
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center mt-3 mb-3">
                                                        <InputGroup>
                                                            <Form.Control onChange={this.setMinPrice} type="number" placeholder={t('common:min')} value={this.state.min_value} />
                                                        </InputGroup>
                                                        <span>to</span>
                                                        <InputGroup>
                                                            <Form.Control onChange={this.setMaxPrice} type="number" placeholder={t('common:max')} value={this.state.max_value} />
                                                        </InputGroup>
                                                    </div>
                                                    {this.state.priceError && <div className='valmsg mb-2'>{this.state.priceError}</div>}
                                                    <div className={`d-flex ${styles.btns}`}>
                                                        <button className='btn buttonStroke flex-grow-1 w-100' onClick={this.resetPrice}>{t('common:clear')}</button>
                                                        <button disabled={this.state.abDis} className='btn flex-grow-1 w-100' onClick={this.applyPrice}>{t('common:apply')}</button>
                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
}
export default withTranslation()(CategoryFilterDesktop);
