import React from 'react';

function MenuIocn({className}) {
    return (
        <svg
        className={className}
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H15C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0H1Z"
          fill="#777E90"
        />
        <path
          d="M3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H13C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6H3Z"
          fill="#777E90"
        />
      </svg>
    );
}

export default MenuIocn;