import React, {createContext, useEffect, useMemo, useState} from "react";
import ConstHelper  from '../helper/constHelper';

export const ThemeContext = createContext(null);


function Context({ children }) {

    const [currentTheme, setCurrentTheme] = useState(ConstHelper.DARK_THEME);

    useEffect(()=>{
        if (localStorage.getItem('theme') && localStorage.getItem('theme') !== '') {
            setCurrentTheme(localStorage.getItem('theme'));
        }
    },[])

    const themeValue = useMemo(()=>{
        return { currentTheme, setCurrentTheme }
    },[currentTheme])

    return (
        <ThemeContext.Provider value={themeValue}>
            {children}
        </ThemeContext.Provider>
    );
}

export default Context