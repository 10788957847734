import React, { PureComponent } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Image from 'next/image';
import Link from 'next/link'
import SignupEarlyAccess from "../../../public/NFTapp/sign-up-early-access.png";
import success from "../../../public/NFTapp/success.png";
import { withTranslation } from 'next-i18next';
// API helper
import { apiHelperBlockchain } from '../../../helper/apiHelper';
// loader
import OnlyLoader from "../loader/onlyLoader";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from "./easyAccessModal.module.scss"
class Index extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: { name: "", email: "" },
            errors: {},
            onceClick: false,
            contactLoader: false
        }
    }

    formValidation = () => {

        // Remove validation if input have value
        const { data: { name, email } } = this.state;
        var reEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

        // Form validation
        let err = {};
        if (!name) { err.name = this.props.t('common:enter_name') }   
        if (!email) { err.email = this.props.t('common:enter_email') } else if (email !== '' && reEmail.test(email) === false) { err.email = this.props.t('common:error_valid_email') }

        this.setState({ errors: err });
    }

    handleChange = (e) => {
        const inpData = { ...this.state.data };
        inpData[e.target.name] = e.target.value;
        if (this.state.onceClick) {
            this.formValidation();
        }
        this.setState({ data: inpData });
    }

    closeSignupModal = () => {
        this.setState({
            data: { name: "", email: "" }
        }, () => {
            this.props.changeForSignupModal(false)
        })
    }

    saveEasyAccess = () => {
        // this.props.changeForAfterSignupModal(true)
        this.setState({ onceClick: true });
        this.formValidation();

        const reEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        if (this.state.data.name !== "" && this.state.data.email !== "" && reEmail.test(this.state.data.email)) {

            this.setState({ contactLoader: true });

            let pData = { name: this.state.data.name, email: this.state.data.email };

            // add-user  
            apiHelperBlockchain('add-user', 'POST', pData).then(res => {
                if (res.data.status) {
                    toast(res.data.message);
                    this.props.changeForAfterSignupModal(true)
                }
                else {
                    toast(res.data.message);
                }
                this.setState({ contactLoader: false, onceClick: false, data: { name: "", email: "" }, error: {} });
            }).catch(error => console.error(`Error: ${error}`));

        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Modal backdrop={"static"} show={this.props.showModal} onHide={this.closeSignupModal} centered size="lg" className={styles.p0}>
                    <div className={`d-md-flex ${styles.earlyAccessModal}`}>
                        <div className={`d-flex align-items-center justify-content-center ${styles.imgBlock}`}>
                            <Image src={SignupEarlyAccess} alt={t('common:early_access')} />
                        </div>
                        <div className={styles.earlyAccessDetails}>
                            <div className="popup__title h4">{t('common:early_access')}
                                <Button type="button" className="btnClose" onClick={this.closeSignupModal}>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'><path fillRule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='#23262F' /></svg>
                                </Button>
                            </div>
                            <div className={styles.formGroup}>
                                <label className='lbl'>{t('common:your_name_lbl')}</label>
                                <input type="text" placeholder={t('common:enter_name')} className='form-control' value={this.state.data.name} name="name" onChange={this.handleChange} />
                                <div className='valmsg'>{this.state.errors.name && this.state.errors.name !== '' ? this.state.errors.name : ''}</div>
                            </div>
                            <div className={styles.formGroup}>
                                <label className='lbl'>{t('common:email_lbl')}</label>
                                <input type="email" placeholder={t('common:enter_email')} className='form-control' value={this.state.data.email} name="email" onChange={this.handleChange} />
                                <div className='valmsg'>{this.state.errors.email && this.state.errors.email !== '' ? this.state.errors.email : ''}</div>
                            </div>
                            <Button className='btn w-100' onClick={this.saveEasyAccess} disabled={this.state.contactLoader ? true : false} >
                                {this.state.contactLoader ? <OnlyLoader /> : t('common:submit')}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal backdrop={"static"} show={this.props.AfterSubmitModal} onHide={() => this.props.changeForAfterSignupModal(false)} centered size="md" className={styles.subDetails}>
                    <div className={`text-center ${styles.submitting}`}>
                        <div className="popup__title h4">
                            <div className="bg-transparent btnClose" onClick={() => this.props.changeForAfterSignupModal(false)}>
                            </div>
                        </div>
                        <div className={styles.img}>
                            <Image src={success} alt={t('common:thank_you_msg')} />
                        </div>
                        <div className="popup__title h4 mb-3">{t('common:thank_you_msg')}</div>
                        <p>{t('common:thank_you_des_1')} <Link href='/blog'><a className='text-underline'>{t('common:thank_you_des_2')}</a></Link> {t('common:thank_you_des_3')}</p>
                    </div>
                </Modal>

                <ToastContainer />
            </>
        )
    }
}

export default withTranslation()(Index)