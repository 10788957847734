import React, {Fragment, useEffect} from 'react';
import {useDisconnect} from "wagmi";

const MoralisLogOut = () => {
    const { disconnectAsync } = useDisconnect();

    useEffect(() => {
        disconnectAsync();
    }, [disconnectAsync]);

    return <Fragment />

}

export default MoralisLogOut;