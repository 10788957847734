import React from 'react';

function RemoveIcon() {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.1654 3.66667H14.582V1.83333C14.582 1.3471 14.3889 0.880788 14.0451 0.536971C13.7012 0.193154 13.2349 0 12.7487 0L7.2487 0C6.76247 0 6.29615 0.193154 5.95234 0.536971C5.60852 0.880788 5.41537 1.3471 5.41537 1.83333V3.66667H0.832031V5.5H2.66536V19.25C2.66536 19.9793 2.9551 20.6788 3.47082 21.1945C3.98655 21.7103 4.68602 22 5.41537 22H14.582C15.3114 22 16.0109 21.7103 16.5266 21.1945C17.0423 20.6788 17.332 19.9793 17.332 19.25V5.5H19.1654V3.66667ZM7.2487 1.83333H12.7487V3.66667H7.2487V1.83333ZM15.4987 19.25C15.4987 19.4931 15.4021 19.7263 15.2302 19.8982C15.0583 20.0701 14.8251 20.1667 14.582 20.1667H5.41537C5.17225 20.1667 4.93909 20.0701 4.76718 19.8982C4.59528 19.7263 4.4987 19.4931 4.4987 19.25V5.5H15.4987V19.25Z" fill="" />
            <path d="M9.08333 9.1665H7.25V16.4998H9.08333V9.1665Z" fill="" />
            <path d="M12.7474 9.1665H10.9141V16.4998H12.7474V9.1665Z" fill="" />
        </svg>
    );
}

export default RemoveIcon;