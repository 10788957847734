import React, {Fragment, useEffect} from 'react';
import {useMoralis} from "react-moralis";
import {useAccount, useNetwork} from "wagmi";
import axios from "axios";
import { ethers } from 'ethers';
import { chainIdHEX } from '../../config/ethereum-config';

const MoralisWalletBalance = ({ getTokenBalance }) => {

    // const { Moralis } = useMoralis();
    const { chain } = useNetwork();
    const { address } = useAccount();

    useEffect(() => {

        const callAsyncFunc = async () => {
            const chain = process.env.TYPE === "prod" ? "avax" : chainIdHEX[0]
            const options = {
                method: 'GET',
                url: `https://deep-index.moralis.io/api/v2/${address}/balance`,
                params: { chain },
                headers: { accept: 'application/json', 'X-API-Key': 'ytHvdq0bKzDMNuYnWyyArowhz4tZpqTCEvOZLnOYcLr571h8BSRDvnduSiV94LCO' }
            };

            axios
                .request(options)
                .then(function (response) {
                    const bal = response?.data?.balance || 0;
                    const tokenValue = ethers.utils.formatUnits(bal);
                    getTokenBalance(tokenValue);
                })
                .catch(function (error) {
                });
        }
        callAsyncFunc()

    }, [address, chain]);
    return <Fragment />
}


export default MoralisWalletBalance;