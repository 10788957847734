import React from 'react';

function FullviewLink() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1747 15.999H1.8821C0.846947 15.999 0 15.152 0 14.1169V2.82424C0 1.78909 0.846947 0.942139 1.8821 0.942139H5.64631C6.21094 0.942139 6.58737 1.31856 6.58737 1.88319C6.58737 2.44782 6.21094 2.82424 5.64631 2.82424H1.8821V14.1169H13.1747V10.3527C13.1747 9.78803 13.5512 9.41161 14.1158 9.41161C14.6804 9.41161 15.0568 9.78803 15.0568 10.3527V14.1169C15.0568 15.152 14.2099 15.999 13.1747 15.999Z" fill="black" />
            <path d="M15.0565 6.58834C14.4918 6.58834 14.1154 6.21192 14.1154 5.64729V1.88308H10.3512C9.78658 1.88308 9.41016 1.50666 9.41016 0.942029C9.41016 0.377397 9.78658 0.000976562 10.3512 0.000976562H15.0565C15.6211 0.000976562 15.9975 0.377397 15.9975 0.942029V5.64729C15.9975 6.21192 15.6211 6.58834 15.0565 6.58834Z" fill="black" />
            <path d="M8.47035 8.47045C8.18803 8.47045 7.99982 8.37634 7.81161 8.18813C7.43519 7.81171 7.43519 7.24708 7.81161 6.87066L14.399 0.283292C14.7754 -0.0931287 15.34 -0.0931287 15.7165 0.283292C16.0929 0.659713 16.0929 1.22434 15.7165 1.60077L9.12909 8.18813C8.94087 8.37634 8.75266 8.47045 8.47035 8.47045Z" fill="black" />
        </svg>
    );
}

export default FullviewLink;