export default function MyItems(props) {
    return (
        <svg className="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0013 3.33415C8.37444 3.33415 6.87477 3.46832 5.67106 3.62729C4.58774 3.77037 3.77086 4.58725 3.62778 5.67057C3.46881 6.87428 3.33464 8.37395 3.33464 10.0008C3.33464 11.6277 3.46881 13.1273 3.62778 14.3311C3.77086 15.4144 4.58774 16.2313 5.67106 16.3743C6.87477 16.5333 8.37444 16.6675 10.0013 16.6675C11.6282 16.6675 13.1278 16.5333 14.3315 16.3743C15.4149 16.2313 16.2317 15.4144 16.3748 14.3311C16.5338 13.1273 16.668 11.6277 16.668 10.0008C16.668 8.37395 16.5338 6.87428 16.3748 5.67057C16.2317 4.58725 15.4149 3.77037 14.3315 3.62729C13.1278 3.46832 11.6282 3.33415 10.0013 3.33415ZM5.45284 1.97497C3.62217 2.21675 2.21724 3.62168 1.97546 5.45235C1.80925 6.71088 1.66797 8.28521 1.66797 10.0008C1.66797 11.7164 1.80925 13.2907 1.97546 14.5493C2.21724 16.3799 3.62217 17.7849 5.45283 18.0267C6.71137 18.1929 8.2857 18.3341 10.0013 18.3341C11.7169 18.3341 13.2912 18.1929 14.5498 18.0267C16.3804 17.7849 17.7854 16.3799 18.0271 14.5493C18.1934 13.2907 18.3346 11.7164 18.3346 10.0008C18.3346 8.28521 18.1934 6.71088 18.0271 5.45235C17.7854 3.62168 16.3804 2.21675 14.5498 1.97497C13.2912 1.80876 11.7169 1.66748 10.0013 1.66748C8.2857 1.66748 6.71137 1.80876 5.45284 1.97497Z" fill="#777E91" />
            <path d="M7.49953 9.16781C8.42001 9.16781 9.1662 8.42161 9.1662 7.50114C9.1662 6.58066 8.42001 5.83447 7.49953 5.83447C6.57906 5.83447 5.83287 6.58066 5.83287 7.50114C5.83287 8.42161 6.57906 9.16781 7.49953 9.16781Z" fill="#777E91" />
            <path d="M15.1006 10.5906L16.5959 12.0858C16.5443 12.866 16.469 13.5899 16.3859 14.2329L13.9221 11.7691C13.5967 11.4436 13.069 11.4436 12.7436 11.7691L10.934 13.5787C9.95765 14.555 8.37474 14.555 7.39843 13.5787L7.25545 13.4357C6.93001 13.1103 6.40238 13.1103 6.07694 13.4357L4.06939 15.4433C3.83682 15.128 3.68122 14.7495 3.626 14.3314C3.59588 14.1034 3.56665 13.8647 3.53906 13.6166L4.89843 12.2572C5.87474 11.2809 7.45765 11.2809 8.43396 12.2572L8.57694 12.4002C8.90238 12.7256 9.43002 12.7256 9.75545 12.4002L11.5651 10.5905C12.5414 9.61424 14.1243 9.61424 15.1006 10.5906Z" fill="#777E91" />
        </svg>
    )
}

