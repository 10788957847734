import React, { Component, useEffect, Fragment } from 'react';
import { withTranslation } from 'next-i18next';
// Page Plugins
import { Modal } from "react-bootstrap";
import { withRouter } from 'next/router';
import { useMoralis } from "react-moralis";
import { useAccount, useDisconnect, useNetwork } from 'wagmi';


const MoralisLogOut = ({disconnectTrigger,moralisChainID}) => {
    // const { logout,chainId } = useMoralis();
    // const { switchNetwork } = useChain();
    const { disconnectAsync } = useDisconnect();
    const { isConnected, address } = useAccount();
    const { chain } = useNetwork();
    // const { chains, error, isLoading, pendingChainId, switchNetwork } =
    // useSwitchNetwork();

    // useEffect(() => {
    //     disconnectTrigger(disconnectAsync);
    //     moralisChainID(chain?.id);
    //     // moralisSwitchNetwork(switchNetwork);
    // }, [chain, isConnected,disconnectAsync]);
    return <Fragment />
}

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentNetwork : "",
            netModal : false,
            logOutTrigger: null,
            chainId: "",
            switchingNetwork:null
        }
    }

    componentDidMount = () => {
        this.setState({ netModal : this.props.networkChangeModal });
        if(localStorage.getItem('chainType'))
        {
            this.setState({ currentNetwork : localStorage.getItem('chainType') });
        }
        // const localChainId = localStorage.getItem('chain_id');
        // if(localChainId){
        //     this.setState({ chainId : localChainId });
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState({ netModal : this.props.networkChangeModal });
            if(localStorage.getItem('chainType'))
            {
                this.setState({ currentNetwork : localStorage.getItem('chainType') });
            }
            // const localChainId = localStorage.getItem('chain_id');
            // if(localChainId){
            //     this.setState({ chainId : localChainId });
            // }
        }
    }

    disconnectLogin = () => {
        this.state.logOutTrigger();
        localStorage.clear();
        if(this.props.currLocale){
            window.location = "/" + this.props.currLocale + "/nft" + "/login";
        }else{
            window.location = "/" + "en" + "/nft" + "/login";
        }
    }

    render() {
        const { t } = this.props
 
        return (
            <>
            {!this.props.fromBuy ? 
            <>
            <MoralisLogOut disconnectTrigger = {(res)=>this.setState({logOutTrigger: res})} moralisChainID={(res)=> this.setState({chainId:res})} moralisSwitchNetwork={(res)=> this.setState({switchingNetwork:res})} />
            <Modal backdrop={"static"}
               show={this.state.netModal}
               size="md"
               centered
               aria-labelledby="contained-modal-title-vcenter">
               <div className="popup placeBid mb-0">
                   <div className="steps">
                       <div className="steps__item">
                           <div className="steps__head d-block text-center">
                               <div className="steps__icon m-auto mb-4">
                                   <div className="loader-circle"></div>
                               </div>
                               <div className="steps__details">
                                   <div className="steps__info">{t('common:switch_net_disconnect')} {" "}
                                   </div>
                                   <div className="steps__text mt-2 mb-4">{t('common:wallet_con_to')} { this.state.currentNetwork } {" "}, {t('common:switch_to_mainnet')}
                                      {/* {" "} {(() => {
                                           t('common:goerli')
                                       })()} */}
                                       </div>
                                       <button className='btn w-100' onClick={this.disconnectLogin}>{t('common:disconnect')}</button>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </Modal>
            </>:<></>}
            </>
        )
    }
}

export default withTranslation()(withRouter(Index));