import React from 'react';

function ReadMoreIcon({className}) {
    return (
        <svg className={className} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.83768 11.3749C8.83915 11.3734 8.8406 11.3718 8.84207 11.3703L13.3746 6.6343C13.4104 6.59667 13.442 6.55528 13.4689 6.51086L13.5386 6.37587L13.5669 6.28425L13.5653 6.21756C13.5818 6.11496 13.5795 6.0102 13.5584 5.90841L13.557 5.84781L13.5553 5.77506L13.4985 5.67324C13.466 5.61652 13.426 5.56437 13.3797 5.51823L8.60705 0.974383C8.29115 0.670027 7.78831 0.679376 7.48395 0.995281C7.48248 0.996792 7.48104 0.998332 7.47956 0.999872C7.18723 1.32048 7.19839 1.81421 7.50492 2.12131L10.3317 4.82904C10.4516 4.9459 10.4541 5.13786 10.3372 5.25774C10.2828 5.31353 10.209 5.34624 10.1312 5.34908L1.10522 5.55312C0.666637 5.56301 0.319123 5.92654 0.329009 6.36512C0.338895 6.8037 0.702426 7.15119 1.14101 7.14133C1.14104 7.14133 1.14109 7.14133 1.14112 7.14133L10.1671 6.93728C10.3345 6.93586 10.4714 7.07042 10.4728 7.23783C10.4735 7.31571 10.4442 7.39089 10.3909 7.44773L7.68909 10.2683C7.39291 10.5898 7.40417 11.088 7.71458 11.3958C8.03048 11.7002 8.53333 11.6908 8.83768 11.3749Z" fill="#3772FF" />
        </svg>
    );
}

export default ReadMoreIcon;