export default function ProfileIcon(props) {
    return (
        <svg className="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4856_86378)">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 10.8341C6.77834 10.8341 4.16667 13.4458 4.16667 16.6675V18.3341C4.16667 18.7944 3.79357 19.1675 3.33333 19.1675C2.8731 19.1675 2.5 18.7944 2.5 18.3341V16.6675C2.5 12.5253 5.85786 9.16748 10 9.16748C14.1421 9.16748 17.5 12.5253 17.5 16.6675V18.3341C17.5 18.7944 17.1269 19.1675 16.6667 19.1675C16.2064 19.1675 15.8333 18.7944 15.8333 18.3341V16.6675C15.8333 13.4458 13.2217 10.8341 10 10.8341Z" fill="#777E91" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10 9.16781C11.8409 9.16781 13.3333 7.67542 13.3333 5.83447C13.3333 3.99352 11.8409 2.50114 10 2.50114C8.15905 2.50114 6.66667 3.99352 6.66667 5.83447C6.66667 7.67542 8.15905 9.16781 10 9.16781ZM10 10.8345C12.7614 10.8345 15 8.5959 15 5.83447C15 3.07305 12.7614 0.834473 10 0.834473C7.23858 0.834473 5 3.07305 5 5.83447C5 8.5959 7.23858 10.8345 10 10.8345Z" fill="#777E91" />
            </g>
            <defs>
                <clipPath id="clip0_4856_86378">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.000976562)" />
                </clipPath>
            </defs>
        </svg>
    )
}

