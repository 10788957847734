const constants = {
    LIGHT_THEME : 'light',
    DARK_THEME : 'dark',
    categoryTags : [
        {tag: "category : Arabic 999 club", pushurl: "arabic999_club" },
        {tag: "category : Arabic 10k club", pushurl: "arabic10k_club" },
        {tag: "category : Arabic 100k club", pushurl: "arabic100k_club" },
        {tag: "category : 100k club", pushurl: "100k_club" },
        {tag: "category : 10k club", pushurl: "10k_club" }
    ],
    HOURS_48 : 48
}

export default constants;