import React from 'react';

function TimerIcon() {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 14.2579C10.6819 14.2579 13.6667 11.2731 13.6667 7.59123C13.6667 3.90933 10.6819 0.924561 7 0.924561C3.3181 0.924561 0.333334 3.90933 0.333334 7.59123C0.333334 11.2731 3.3181 14.2579 7 14.2579ZM7.66667 4.25789C7.66667 3.8897 7.36819 3.59123 7 3.59123C6.63181 3.59123 6.33333 3.8897 6.33333 4.25789V7.59123C6.33333 7.76804 6.40357 7.93761 6.5286 8.06263L8.19526 9.7293C8.45561 9.98965 8.87772 9.98965 9.13807 9.7293C9.39842 9.46895 9.39842 9.04684 9.13807 8.78649L7.66667 7.31509V4.25789Z" fill="#777E91" />
        </svg>
    );
}

export default TimerIcon;