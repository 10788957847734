import dynamic from 'next/dynamic';
const IndexVersionTwo = dynamic(() => import('../../components/home2'));
// helpers
import { apiHelper, apiHelperWordpressForBlog } from '../../helper/apiHelper';
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

// export const config = {
//   unstable_runtimeJS: false
// }

export default function CommonHome(props) {
  return (
    
    <>
  
    
    {/* <IndexVersionTwo resources={props.resources} meta={props.meta} faq={props.faq} currLocale={props.currLocale} categoryList={props.catList} /> */}
    <IndexVersionTwo resources={props.resources} faq={props.faq} currLocale={props.currLocale} categoryList={props.catList} />
    </>
  )
}

export const getStaticProps = async ({ locale }) => {
  const allTopicQuery = `query NewQuery {
    ${locale === 'en' ? 'allBlogEN' : 'allBlogAR'} {
      nodes {
        id
        slug
        title
        date
        content
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        ${locale === 'en' ? 'blogCategoriesEN' : 'blogCategoriesAR'} {
          nodes {
            name
          }
        }
        ${locale === 'en' ? 'blogAuthorEN' : 'blogAuthorAR'} {
          nodes {
            authorTags {
              shortBio
              profileImage {
                sourceUrl
              }
            }
            name
            slug
          }
        }   
      }
    }
  }`

  // const resResource = await apiHelperWordpressForBlog(allTopicQuery);
  // // console.log('resResource...',resResource);
  // const allResource = (locale === 'en' ? resResource.data.data.allBlogEN : resResource.data.data.allBlogAR).nodes;
  // if (allResource.length === 0) {
  //   return {
  //     notFound: true,
  //   }
  // }

  // var resourcesArr = allResource && allResource.length > 0 && allResource.map(tag => {
  //   var catType = locale === 'en' ? tag.blogCategoriesEN : tag.blogCategoriesAR
  //   var author = (locale === 'en' ? tag.blogAuthorEN : tag.blogAuthorAR).nodes

  //   if (author && author.length > 0) {
  //     author = author[0]
  //     author = { ...author, shortBio: author.authorTags.shortBio, profileImage: author.authorTags.profileImage && author.authorTags.profileImage.sourceUrl ? author.authorTags.profileImage.sourceUrl : '' }
  //   } else {
  //     author = null
  //   }

  //   var rearr = {
  //     ...tag,
  //     feature_image: (tag.featuredImage && tag.featuredImage.node.sourceUrl ? tag.featuredImage.node.sourceUrl : ''),
  //     featuredImageAlt: (tag.featuredImage && tag.featuredImage.node.altText ? tag.featuredImage.node.altText : ''),
  //     articleCategory: (catType && catType.nodes && catType.nodes.length > 0 ? catType.nodes : ''),
  //     author: author
  //   }

  //   delete rearr.featuredImage
  //   rearr.author ? delete rearr.author.authorTags : ''
  //   if (locale === 'en') { delete rearr.blogCategoriesEN; } else { delete rearr.blogCategoriesAR; }

  //   return rearr
  // })

  var pageName = 'index';
  var postData = {
    language: locale,
    pageName: pageName
  }

  // const resMeta = await apiHelper(`metadata/getNftMetadata`, "POST", postData, null);
  // const meta = resMeta.data.status ? resMeta.data.data : null;

  const resFAQ = await apiHelper(`metadata/getNftPagesFaqs/${pageName}/${locale}`, "GET", null, null);
  const faq = resFAQ.data.status ? resFAQ.data.data : null;

  const canonical = process.env.hostBaseUrl + '/' + locale + '/nft';

  return {
    props: {
      resources: [],
      // (resourcesArr.length > 9 ? resourcesArr.splice(0, 8) : resourcesArr),
      // meta,
      faq,
      ogImage: 'nft-marketplace-en-social.png',
      revalidate: 10,
      currLocale: locale,
      canonical,
      dynamicURL: '/nft',
      // pageName: meta && meta.pageHeading && meta.pageHeading !== '' ? meta.pageHeading : (locale === 'en' ? 'Launch, Buy and Sell NFTs from Top NFT Marketplace' : 'قم باطلاق وبيع وشراء الرموز غير القابلة للاستبدال من أفضل سوق NFT.'),
      ...(await serverSideTranslations(locale, ['common']))
    },
    revalidate: 10, // In seconds
  }
}