import React from 'react';

function SwitchView() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9988 15H4.995V17.5L0 13.75L4.995 10V12.5H19.9988V15ZM0 7.5H14.9988V10L19.9988 6.25L14.9988 2.5V5H0V7.5Z" fill="#3772FF" />
        </svg>
    );
}

export default SwitchView;