export default function PolygonSVG(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="#F3EBFF" />
            <g clipPath="url(#clip0_5805_164239)">
                <path d="M25.3134 16.0897C24.8955 15.8509 24.3582 15.8509 23.8806 16.0897L20.5373 18.0598L18.2687 19.3136L14.9851 21.2837C14.5672 21.5225 14.0299 21.5225 13.5522 21.2837L10.9851 19.7315C10.5672 19.4927 10.2687 19.0151 10.2687 18.4777V15.4927C10.2687 15.0151 10.5075 14.5374 10.9851 14.2389L13.5522 12.7464C13.9701 12.5076 14.5075 12.5076 14.9851 12.7464L17.5522 14.2986C17.9701 14.5374 18.2687 15.0151 18.2687 15.5524V17.5225L20.5373 16.2091V14.1792C20.5373 13.7016 20.2985 13.224 19.8209 12.9255L15.0448 10.1195C14.6269 9.88073 14.0896 9.88073 13.6119 10.1195L8.71642 12.9852C8.23881 13.224 8 13.7016 8 14.1792V19.7912C8 20.2688 8.23881 20.7464 8.71642 21.0449L13.5522 23.8509C13.9701 24.0897 14.5075 24.0897 14.9851 23.8509L18.2687 21.9404L20.5373 20.627L23.8209 18.7165C24.2388 18.4777 24.7761 18.4777 25.2537 18.7165L27.8209 20.2091C28.2388 20.4479 28.5373 20.9255 28.5373 21.4628V24.4479C28.5373 24.9255 28.2985 25.4031 27.8209 25.7016L25.3134 27.1942C24.8955 27.433 24.3582 27.433 23.8806 27.1942L21.3134 25.7016C20.8955 25.4628 20.597 24.9852 20.597 24.4479V22.5374L18.3284 23.8509V25.821C18.3284 26.2986 18.5672 26.7763 19.0448 27.0748L23.8806 29.8807C24.2985 30.1195 24.8358 30.1195 25.3134 29.8807L30.1493 27.0748C30.5672 26.836 30.8657 26.3583 30.8657 25.821V20.1494C30.8657 19.6718 30.6269 19.1942 30.1493 18.8957L25.3134 16.0897Z" fill="#8247E5" />
            </g>
            <defs>
                <clipPath id="clip0_5805_164239">
                    <rect width="22.9254" height="20" fill="white" transform="translate(8 10)" />
                </clipPath>
            </defs>
        </svg>
    )
}

