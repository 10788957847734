import React from 'react';

function FooterYoutubeIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17371_34690)">
                <path d="M29.3737 8.08822C29.1998 7.49392 28.8682 6.95245 28.4108 6.51615C27.9533 6.07985 27.3857 5.76348 26.7627 5.59762C24.4243 5.00002 15.0134 5.00002 15.0134 5.00002C15.0134 5.00002 5.62612 4.98809 3.26398 5.59762C2.64095 5.76348 2.07332 6.07985 1.61593 6.51615C1.15855 6.95245 0.82689 7.49392 0.653013 8.08822C0.209748 10.3719 -0.00878258 12.6901 0.000270091 15.0125C-0.00707122 17.3262 0.211453 19.6356 0.653013 21.9107C0.82689 22.5048 1.15855 23.0463 1.61593 23.4827C2.07332 23.919 2.64095 24.2353 3.26398 24.4012C5.59985 25 15.0134 25 15.0134 25C15.0134 25 24.3993 25 26.7627 24.4012C27.3857 24.2353 27.9533 23.919 28.4108 23.4827C28.8682 23.0463 29.1998 22.5048 29.3737 21.9107C29.806 19.6347 30.0153 17.3254 29.999 15.0125C30.017 12.6909 29.8077 10.3727 29.3737 8.08822ZM12.0097 19.296V10.716L19.8427 15.0125L12.0097 19.296Z" fill="#FF0000" />
            </g>
            <defs>
                <clipPath id="clip0_17371_34690">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default FooterYoutubeIcon;