import React, { Component } from "react";
import { withTranslation } from "next-i18next";
// Page Plugins
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "next/router";
class Index extends Component {
  constructor(props) {
    super(props);
  }

  sign_in = () => {
    this.props.signWithDiffACcounnt();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Modal
          backdrop={"static"}
          show={this.props.isSignWithDiffAccount}
          onHide={this.props.disconnectForDiffAcc}
          size="md"
          centered
          className="placeBid"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="popup popup_bid">
            <div className="popup__title h4">
              <Button
                type="button"
                className="btnClose"
                onClick={this.props.disconnectForDiffAcc}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="15"
                  fill="none"
                  viewBox="0 0 14 15"
                >
                  <path
                    fillRule="evenodd"
                    d="M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z"
                    fill="#23262F"
                  />
                </svg>
              </Button>
            </div>
            <div className="text-center steps__info">
              <svg
                width="40"
                height="40"
                viewBox="0 0 560 560"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_401_2)">
                  <path
                    d="M280 560C434.64 560 560 434.64 560 280C560 125.36 434.64 0 280 0C125.36 0 0 125.36 0 280C0 434.64 125.36 560 280 560Z"
                    fill="#E84142"
                  />
                  <path
                    d="M378.772 287.276C388.472 270.52 404.126 270.52 413.827 287.276L474.236 393.323C483.937 410.079 476 423.748 456.598 423.748H334.898C315.717 423.748 307.78 410.079 317.26 393.323L378.772 287.276ZM261.921 83.1181C271.622 66.3622 287.055 66.3622 296.756 83.1181L310.205 107.37L341.953 163.15C349.669 179.024 349.669 197.764 341.953 213.638L235.465 398.173C225.764 413.165 209.669 422.646 191.811 423.748H103.402C84 423.748 76.063 410.299 85.7638 393.323L261.921 83.1181Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_401_2">
                    <rect width="560" height="560" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="popup__info mt-4">
                {t("common:switchAccount")}
              </div>
            </div>
          </div>
          <div className="popup__btns d-flex flex-wrap flex-md-nowrap">
            <Button type="button" className="btn" onClick={this.sign_in}>
              {t("common:Connect_wallet")}
            </Button>
            <Button
              type="button"
              className="btn buttonStroke"
              onClick={this.props.disconnectForDiffAcc}
            >
              {t("common:disconnect")}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(withRouter(Index));
