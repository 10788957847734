import React from 'react';

function FooterTelegramIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17371_34683)">
                <path d="M15 0C6.71566 0 0 6.71566 0 15C0 23.2843 6.71566 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71566 23.2843 0 15 0Z" fill="#40B3E0" />
                <path d="M22.2991 8.6278L19.6198 22.1359C19.6198 22.1359 19.2452 23.0727 18.2147 22.623L12.0321 17.883L9.78402 16.7965L5.99957 15.5224C5.99957 15.5224 5.41879 15.3164 5.36254 14.8667C5.30629 14.4171 6.01832 14.1736 6.01832 14.1736L21.0624 8.27202C21.0624 8.27202 22.2989 7.72874 22.2989 8.62803" fill="white" />
                <path d="M11.5543 21.9834C11.5543 21.9834 11.3738 21.9666 11.1488 21.2545C10.9242 20.5426 9.78125 16.7957 9.78125 16.7957L18.8677 11.0254C18.8677 11.0254 19.3924 10.7068 19.3736 11.0254C19.3736 11.0254 19.4673 11.0816 19.1861 11.3439C18.9052 11.6062 12.0482 17.77 12.0482 17.77" fill="#D2E5F1" />
                <path d="M14.4004 19.7014L11.955 21.931C11.955 21.931 11.7638 22.0761 11.5547 21.9852L12.023 17.8438" fill="#B5CFE4" />
            </g>
            <defs>
                <clipPath id="clip0_17371_34683">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default FooterTelegramIcon;