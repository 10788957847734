import React from 'react';

function MoreOptIcon() {
    return (
        <svg width="18" height="5" viewBox="0 0 18 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 0.0664062C0.9 0.0664062 0 0.966406 0 2.06641C0 3.16641 0.9 4.06641 2 4.06641C3.1 4.06641 4 3.16641 4 2.06641C4 0.966406 3.1 0.0664062 2 0.0664062ZM16 0.0664062C14.9 0.0664062 14 0.966406 14 2.06641C14 3.16641 14.9 4.06641 16 4.06641C17.1 4.06641 18 3.16641 18 2.06641C18 0.966406 17.1 0.0664062 16 0.0664062ZM9 0.0664062C7.9 0.0664062 7 0.966406 7 2.06641C7 3.16641 7.9 4.06641 9 4.06641C10.1 4.06641 11 3.16641 11 2.06641C11 0.966406 10.1 0.0664062 9 0.0664062Z" fill="#777E91" />
        </svg>
    );
}

export default MoreOptIcon;