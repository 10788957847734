import React from 'react';

function TickMark() {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33282 0.403577C8.56543 0.178557 8.93457 0.178557 9.16718 0.403578L10.5169 1.70931C10.6499 1.83799 10.8349 1.89808 11.0181 1.87216L12.8776 1.60915C13.198 1.56383 13.4967 1.78081 13.5526 2.09958L13.877 3.94929C13.909 4.13157 14.0233 4.2889 14.1868 4.37564L15.8457 5.25582C16.1316 5.4075 16.2457 5.75858 16.1035 6.04934L15.2788 7.7365C15.1975 7.90277 15.1975 8.09723 15.2788 8.2635L16.1035 9.95066C16.2457 10.2414 16.1316 10.5925 15.8457 10.7442L14.1868 11.6244C14.0233 11.7111 13.909 11.8684 13.877 12.0507L13.5526 13.9004C13.4967 14.2192 13.198 14.4362 12.8776 14.3908L11.0181 14.1278C10.8349 14.1019 10.6499 14.162 10.5169 14.2907L9.16718 15.5964C8.93457 15.8214 8.56543 15.8214 8.33282 15.5964L6.98309 14.2907C6.85008 14.162 6.66513 14.1019 6.48188 14.1278L4.62244 14.3908C4.30199 14.4362 4.00335 14.2192 3.94743 13.9004L3.62297 12.0507C3.59099 11.8684 3.47669 11.7111 3.3132 11.6244L1.65429 10.7442C1.3684 10.5925 1.25433 10.2414 1.39646 9.95066L2.22119 8.2635C2.30247 8.09723 2.30247 7.90277 2.22119 7.7365L1.39646 6.04934C1.25433 5.75858 1.3684 5.4075 1.65429 5.25582L3.3132 4.37564C3.47669 4.2889 3.59099 4.13157 3.62297 3.94929L3.94743 2.09958C4.00335 1.78081 4.302 1.56383 4.62244 1.60915L6.48188 1.87216C6.66513 1.89808 6.85008 1.83799 6.98309 1.70931L8.33282 0.403577Z" fill="#3772FF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5472 6.12204C11.71 6.28476 11.71 6.54858 11.5472 6.71129L8.2139 10.0446C8.05118 10.2073 7.78736 10.2073 7.62464 10.0446L5.95798 8.37796C5.79526 8.21524 5.79526 7.95142 5.95798 7.78871C6.12069 7.62599 6.38451 7.62599 6.54723 7.78871L7.91927 9.16074L10.958 6.12204C11.1207 5.95932 11.3845 5.95932 11.5472 6.12204Z" fill="#FCFCFD" />
        </svg>
    );
}

export default TickMark;