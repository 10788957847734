import React, { Component } from 'react';
import Router from 'next/router';
import { Container } from "react-bootstrap";
import { withTranslation } from 'next-i18next';
import EasyAccessModal from '../common/easyAccessModal';
import notFoundSvg from "../../public/notFoundSvg.gif";
import Image from 'next/image';
// styles
import styles from "./NotFoundSection.module.scss";
class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currLocale: process.env.defaultLanguage,
            showModal: false,
            AfterSubmitModal: false
        }
    }
    componentDidMount() {
        const router = Router && Router.router;
        this.setState({
            currLocale: router && router.locale && router.locale !== 'default' ? router.locale : process.env.defaultLanguage
        })
    }

    changeForSignupModal = (status) => {
        this.setState({
            showModal: status
        })
    }

    changeForAfterSignupModal = (status) => {
        this.setState({
            AfterSubmitModal: status,
            showModal: false
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="section">
                <Container>
                    <div className={`text-center ${styles.notFound_details}`}>
                        <div className={styles.img}>
                            <Image src={notFoundSvg} alt={t('common:profile_nfound')} width={320} height={180} />
                        </div>
                        <div className={`h5 ${styles.head}`}>
                            {(() => {
                                if (this.props.type === "category") { return t('common:category_nfound') }
                                else if (this.props.type === "collection") { return t('common:collection_nfound') }
                                else if (this.props.type === "collection-outside") { return t('common:collection_nfound_outside') }
                                else if (this.props.type === "profile") { return t('common:profile_nfound') }
                                else if (this.props.type === "nft") { return t('common:nft_nfound') }
                                else if (this.props.type === "activity-profile") { return t('common:activity_profile_nfound') }
                                else if (this.props.type === "activity-collection") { return t('common:activity_collection_nfound') }
                                else if (this.props.type === "no-auctions") { return t('common:nft_nfound') }
                            })()}
                        </div>
                    </div>
                    <EasyAccessModal
                        showModal={this.state.showModal}
                        AfterSubmitModal={this.state.AfterSubmitModal}
                        changeForSignupModal={this.changeForSignupModal}
                        changeForAfterSignupModal={this.changeForAfterSignupModal}
                    />
                </Container>
            </div>

        )
    }
}
export default withTranslation()(NotFound)
