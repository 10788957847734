import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Select from "react-select";
import { withTranslation } from 'next-i18next';
import "../../../node_modules/slick-carousel/slick/slick.css";
import { Card } from "react-bootstrap";
import Link from "next/link";
import Image from "next/image";

import { s3Link } from "../../../config/ethereum-config";

// profile
import profileImg from '../../../public/collection-img.png';
import verified from "../../../public/tick.png";

// API helper
import { apiHelperBlockchain } from "../../../helper/apiHelper";

// styles
import styles from "./TopSellers.module.scss";

//react-shimmer-effects
import { ShimmerSimpleGallery } from "react-shimmer-effects";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper";

class TopSellers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sellerList: [],
            profileSrc: "",
            cAddress: false,
            current: 0,
            pValue: { value: 'verified', label: this.props.t('common:verified') },
            collectionOptions: [
                { value: 'verified', label: this.props.t('common:verified') },
                { value: 'newest', label: this.props.t('common:newest') }
            ],
            sellerLoader: false,
        }
    }

    componentDidMount = () => {
        this.getAllSeller();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            // reinit
            if (prevProps.currLocale !== this.props.currLocale) {
                console.log('currLocale ', this.props.currLocale);
            }
        }
    }

    getAllSeller = async () => {
        this.setState({ sellerLoader: true });
        let tSeller = "top-sellers";
        if (this.state.pValue.value === "verified") {
            tSeller = "top-sellers?isVerified=true";
        }
        apiHelperBlockchain(tSeller, 'GET').then(resp => {
            this.setState({ sellerLoader: false });
            if (resp.data.status) {
                this.setState({ sellerList: resp.data.data });
            }
        }).catch(error => console.error(`Error: ${error}`));
    }

    copyAddress = async (e, wAddress, idx) => {
        e.preventDefault();
        if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
            navigator.clipboard.writeText(wAddress);
        }
        this.setState({ cAddress: true });
        this.setState({ current: idx });
        setTimeout(() => { this.setState({ cAddress: false }); }, 2000);
        document.getElementsByClassName('abcd').attr(dir)
    }

    handleOptions = (e) => {
        this.setState({ pValue: e }, () => { this.getAllSeller(); });
    }

    render() {
        let dragging = false;
        const { sellerList } = this.state;
        const { t } = this.props;
        return (
            <div className={`section ${styles.top_sellers}`}>
                <Container>
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                        <h2 className={`h3 mb-0 d-flex align-items-center ${styles.h3}`}>
                            {this.state.pValue.value === "verified" ?
                                <><span>{t('common:verified_seller')}</span><Image src={verified} alt={t('common:verified_seller')} width="26" height="26" layout='responsive' /></>
                                : t('common:popular_seller')}
                        </h2>
                        <div className={`${styles.selectMenu} selectMenu`}>
                            <Select
                                value={this.state.pValue}
                                onChange={this.handleOptions}
                                options={this.state.collectionOptions}
                                classNamePrefix="select__Inner"
                                placeholder={t('common:select')}
                                isSearchable={false}
                                instanceId="clcMenu"
                            />
                        </div>
                    </div>
                    <div className={styles.topSellers}>
                        {this.state.sellerLoader ? 
                            <div className={styles.shimmerDiv}>
                            <Swiper
                                dir={this.props.currLocale === "ar" ? "rtl" : "ltr"}
                                navigation={{
                                    nextEl: '.button12-next',
                                    prevEl: '.button12-prev',
                                }}
                                modules={[Navigation]}
                                slidesPerView={4}
                                slidesPerGroup={1}
                                spaceBetween={30}
                                speed={400}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 16
                                    },
                                    576: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                    },
                                    1200: {
                                        slidesPerView: 4,
                                    }
                                }}
                            >
                                <SwiperSlide>
                                    <ShimmerSimpleGallery imageHeight={260} row={1} col={1} gap={20} caption />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ShimmerSimpleGallery imageHeight={260} row={1} col={1} gap={20} caption />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ShimmerSimpleGallery imageHeight={260} row={1} col={1} gap={20} caption />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ShimmerSimpleGallery imageHeight={260} row={1} col={1} gap={20} caption />
                                </SwiperSlide>
                                <div className="button12-prev"></div>
                                <div className="button12-next"></div>
                            </Swiper>
                            </div>
                            : sellerList && sellerList.length > 0 ?
                                <>
                                    <Swiper
                                        dir={this.props.currLocale === "ar" ? "rtl" : "ltr"}
                                        navigation={{
                                            nextEl: '.button-next',
                                            prevEl: '.button-prev',
                                        }}
                                        modules={[Navigation]}
                                        slidesPerView={4}
                                        slidesPerGroup={4}
                                        freeMode={true}
                                        mousewheel={true}
                                        grabCursor={true}
                                        speed={400}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 2,
                                                slidesPerGroup: 2,
                                                spaceBetween: 16
                                            },
                                            576: {
                                                slidesPerView: 2,
                                                slidesPerGroup: 2,
                                                spaceBetween: 24
                                            },
                                            768: {
                                                slidesPerView: 3,
                                                slidesPerGroup: 3,
                                                spaceBetween: 24
                                            },
                                            1200: {
                                                slidesPerView: 4,
                                                spaceBetween: 30
                                            },
                                        }}
                                    >
                                        {sellerList && sellerList.map((item, idx) => {
                                            if (item.owner.displayName && item.owner.profilePhoto) {
                                                return (
                                                    <SwiperSlide key={idx}>
                                                        <Card className={styles.card}>
                                                            <Link href={"/nft/profiles/" + (item.owner.customUrl ? item.owner.customUrl : item.owner.walletAddress)}>
                                                                <a>
                                                                    <div className={styles.sellerImg}>
                                                                        <Image src={item.owner.profilePhoto ? s3Link + "profileimage/" + item.owner.profilePhoto : profileImg}
                                                                            alt="team_1"
                                                                            width={256}
                                                                            height={260}
                                                                            layout="responsive"
                                                                        />
                                                                        {/* <div className={`${styles.id} ${i === 1 ? styles.id1 : null} ${i === 2 ? styles.id2 : null} ${i === 3 ? styles.id3 : null} ${i === 4 ? styles.id4 : null}`}>#{i++}</div> */}
                                                                    </div>
                                                                    <Card.Title className={`d-flex align-items-center ${styles.name}`}>
                                                                        <span>
                                                                            {item.owner.displayName ? item.owner.displayName :
                                                                                <>
                                                                                    <div className={styles.user__number}>
                                                                                        <span>
                                                                                            {this.state.cAddress && this.state.current === idx && item.owner.walletAddress ? "Copied !" : (item.owner.walletAddress).substring(1, 8) + "..." + (item.owner.walletAddress).slice(-4)}
                                                                                        </span>
                                                                                        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => this.copyAddress(e, item.owner.walletAddress, idx)}>
                                                                                                <path d="M11.9881 9.67114C12.0031 9.92065 12.2637 10.0889 12.4754 9.9561C13.7908 9.13082 14.6654 7.66762 14.6654 6.00016C14.6654 3.42283 12.576 1.3335 9.99869 1.3335C8.33123 1.3335 6.86803 2.20803 6.04275 3.52345C5.9099 3.73519 6.07821 3.9957 6.32772 4.0107C9.3116 4.19009 11.8087 6.68983 11.9881 9.67114Z" fill="#3772FF" />
                                                                                                <path d="M10.6654 10.0002C10.6654 12.5775 8.57603 14.6668 5.9987 14.6668C3.42137 14.6668 1.33203 12.5775 1.33203 10.0002C1.33203 7.42283 3.42137 5.3335 5.9987 5.3335C8.57603 5.3335 10.6654 7.42283 10.6654 10.0002Z" fill="#3772FF" />
                                                                                            </svg> */}
                                                                                    </div>
                                                                                </>
                                                                            }</span>
                                                                        {item.owner.isVerified && item.owner.isVerified ? <Image src={verified} width={16} height={16} alt="verification" layout='responsive' /> : null}
                                                                    </Card.Title>
                                                                </a>
                                                            </Link>
                                                            <Card.Body className={styles.cardBody}>
                                                                {/* <Card.Text className={styles.eth}>
                                                                <span>{item.value}</span>ETH
                                                            </Card.Text> */}
                                                                <div className={`d-flex ${styles.sellerDetails}`}>
                                                                    {/* {item.owner.linkedinURL ?
                                                                        <Link href={"https://www.linkedin.com/" + item.owner.linkedinURL}>
                                                                            <a className="me-3" target="_blank" rel="nofollow, noopener">
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.1665 0.666504C1.7858 0.666504 0.666504 1.7858 0.666504 3.1665V14.8332C0.666504 16.2139 1.7858 17.3332 3.1665 17.3332H14.8332C16.2139 17.3332 17.3332 16.2139 17.3332 14.8332V3.1665C17.3332 1.7858 16.2139 0.666504 14.8332 0.666504H3.1665ZM4.83317 5.6665C5.2934 5.6665 5.6665 5.2934 5.6665 4.83317C5.6665 4.37294 5.2934 3.99984 4.83317 3.99984C4.37294 3.99984 3.99984 4.37294 3.99984 4.83317C3.99984 5.2934 4.37294 5.6665 4.83317 5.6665ZM4.83317 7.33317C4.37294 7.33317 3.99984 7.70625 3.99984 8.1665V13.1665C3.99984 13.6268 4.37294 13.9998 4.83317 13.9998C5.2934 13.9998 5.6665 13.6268 5.6665 13.1665V8.1665C5.6665 7.70625 5.2934 7.33317 4.83317 7.33317ZM8.99984 10.6665C8.99984 9.746 9.746 8.99984 10.6665 8.99984C11.587 8.99984 12.3332 9.746 12.3332 10.6665V13.1665C12.3332 13.6268 12.7063 13.9998 13.1665 13.9998C13.6268 13.9998 13.9998 13.6268 13.9998 13.1665V10.6665C13.9998 8.82559 12.5074 7.33317 10.6665 7.33317C10.0287 7.33317 9.43259 7.51234 8.926 7.82309C8.79517 7.53417 8.50434 7.33317 8.1665 7.33317C7.70625 7.33317 7.33317 7.70625 7.33317 8.1665V13.1665C7.33317 13.6268 7.70625 13.9998 8.1665 13.9998C8.62675 13.9998 8.99984 13.6268 8.99984 13.1665V10.6665Z" fill="#B1B5C4" />
                                                                                </svg>
                                                                            </a>
                                                                        </Link>
                                                                        : null} */}

                                                                    {item.owner.twitterURL ?
                                                                        <Link href={item.owner.twitterURL}>
                                                                            <a target="_blank" className={styles.socialLink} rel="nofollow, noopener" aria-label='twitter'>
                                                                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M16.4999 4.25C16.9166 10.5 12.3332 15.5 6.49991 15.5C4.48999 15.5 2.48006 15.2307 0.903198 14.4755C0.542048 14.3026 0.683215 13.8208 1.08276 13.7943C3.02449 13.6656 4.63133 13.2019 5.66658 12.1667C1.15291 10.6621 0.374331 5.08292 1.18516 2.17266C1.28036 1.83094 1.72463 1.82916 1.90371 2.13538C3.28827 4.50309 5.92091 5.82503 9.22041 5.51997C9.07766 5.12337 8.99991 4.69577 8.99991 4.25C8.99991 2.17893 10.6788 0.5 12.7499 0.5C14.0151 0.5 15.1339 1.12652 15.8131 2.08622L17.244 1.88179C17.6006 1.83086 17.8494 2.22572 17.6497 2.52539L16.4999 4.25Z" fill="#B1B5C4" />
                                                                                </svg>
                                                                            </a>
                                                                        </Link>
                                                                        : null}

                                                                    {item.owner.portfolioURL ?
                                                                        <Link href={item.owner.portfolioURL.trim()}>
                                                                            <a target="_blank" className={styles.socialLink} rel="nofollow, noopener" aria-label='portfolio'>
                                                                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.9063 0.778899C14.8262 1.86591 16.9722 4.54316 17.2914 7.75781H13.9821C13.8474 4.52379 12.9373 2.2543 11.911 0.780669L11.9063 0.778899ZM8.99927 0.257812C6.46364 1.45946 5.75864 5.32852 5.64795 7.75781H12.3138C12.1981 5.21744 11.4771 1.63077 8.99927 0.257812ZM6.03783 0.799344C5.01684 2.27342 4.11381 4.53742 3.97972 7.75781H0.707031C1.02422 4.56313 3.14559 1.8992 6.03783 0.799344ZM11.9065 16.4034C14.8264 15.3164 16.9724 12.6391 17.2916 9.42448H13.9823C13.8476 12.6585 12.9375 14.928 11.9112 16.4016L11.9065 16.4034ZM8.99947 16.9245C6.46384 15.7228 5.75885 11.8538 5.64815 9.42448H12.314C12.1983 11.9648 11.4773 15.5515 8.99947 16.9245ZM6.03804 16.3829C5.01704 14.9089 4.11401 12.6449 3.97992 9.42448H0.707235C1.02442 12.6192 3.1458 15.2831 6.03804 16.3829Z" fill="#B1B5C4" />
                                                                                </svg>
                                                                            </a>
                                                                        </Link>
                                                                        : null}

                                                                    {item.owner.instagramURL ?
                                                                        <Link href={item.owner.instagramURL}>
                                                                            <a target="_blank" className={styles.socialLink} rel="nofollow, noopener" aria-label='instagram'>
                                                                                <svg fill="#B1B5C4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px"><path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
                                                                                </svg>
                                                                            </a>
                                                                        </Link>
                                                                        : null}

                                                                </div>
                                                                {/* <Link href={"/nft/profiles/" + (item.owner.customUrl ? item.owner.customUrl : item.owner.walletAddress)}>
                                                                    <a className={styles.sellerLink} rel="nofollow, noopener">
                                                                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.09094 9.32659C9.49676 9.7012 10.1294 9.67589 10.504 9.27007L13.7348 5.77011C14.0884 5.38705 14.0884 4.79662 13.7348 4.41355L10.504 0.913514C10.1294 0.507692 9.49677 0.482382 9.09095 0.856984C8.68513 1.23158 8.65982 1.86424 9.03442 2.27007L10.716 4.09183L0.999999 4.09183C0.447714 4.09183 -7.64154e-07 4.53955 -7.86799e-07 5.09183C-8.09444e-07 5.64411 0.447714 6.09183 0.999999 6.09183L10.716 6.09183L9.03443 7.91351C8.65982 8.31932 8.68513 8.95198 9.09094 9.32659Z" fill="#FCFCFD" />
                                                                        </svg>
                                                                    </a>
                                                                </Link> */}
                                                            </Card.Body>
                                                        </Card>
                                                    </SwiperSlide>
                                                )
                                            }
                                        })}
                                    </Swiper>
                                    <div className="button-prev"></div>
                                    <div className="button-next"></div>
                                </>
                                : <p>{t('common:no_sellers_found')}</p>}

                    </div>
                </Container>
            </div>
        )
    }
}
export default withTranslation()(TopSellers);