import Header from "./header/index";
import Footer from "./footer/index";
import Head from "next/head";
import { useRouter } from "next/router";
import { useIntercom } from "react-use-intercom";
import { Fragment, useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import axios from "axios";
import "firebase/messaging";
import { firebaseCloudMessaging } from "../helper/firebaseHelper";

// API helper
import { apiHelperBlockchain } from "../helper/apiHelper";

// styles
import layoutStyles from "../styles/layout.module.scss";

import {
  chainId,
  chainIdHEX,
  chainIdHEX as LocalChainID,
  POLYGON_CHAIN_IDS_Int,
} from "../config/ethereum-config";

import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSignMessage,
  useSwitchNetwork,
} from "wagmi";

import AppBanner from "smart-app-banner-react";
import { useTranslation } from "next-i18next";

//NetworkModal
import NetworkModal from "../components/common/networkModal";

import { fetchBalance, watchAccount, watchNetwork } from "@wagmi/core";

import { useAuthRequestChallengeEvm } from "@moralisweb3/next";
import SignWithDiffAccount from "./common/signWithDiffAccount";
import { ThemeContext } from "../context/Context";
import ConstHelper from "../helper/constHelper";
import { ethers } from "ethers";
import { SiweMessage } from "siwe";
import { useEthersSigner } from "../helper/getSigner";
import { Toaster } from "react-hot-toast";

export default function Layout({ children, pathname }) {
  const [networkChangeModal, setNetworkChangeModal] = useState(false);
  const [triggerSignature, setTriggerSignature] = useState(false);
  const [isNews, setIsNews] = useState(true);
  let router = useRouter();
  let message = "";
  const { signMessageAsync } = useSignMessage();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { chain } = useNetwork();
  const { isConnected, address } = useAccount();
  const [balance, setBalance] = useState(null);

  const signer = useEthersSigner();

  const { disconnectAsync, disconnect } = useDisconnect();

  // Change network trigger
  watchNetwork(async (network) => {
    if (chainId[0] === network?.chain?.id) {
      localStorage.setItem("chainIndex", "1");
      localStorage.setItem("chain_id", chainId[0]);
    } else {
      if (chainId[1] === network?.chain?.id) {
        localStorage.setItem("chain_id", chainId[1]);
      }
      localStorage.setItem("chainIndex", "2");
    }
  });

  function createSiweMessage(address, statement, chainId) {
    const message = new SiweMessage({
      domain: window.location.origin,
      address,
      statement,
      uri: window.location.href,
      version: "1",
      chainId,
    });
    return message.prepareMessage();
  }

  useEffect(() => {
    const check_local_walletAddress = localStorage.getItem("walletAddress");
    if (
      address &&
      isConnected &&
      check_local_walletAddress &&
      check_local_walletAddress?.toLowerCase() !== address?.toLowerCase()
    ) {
      setTriggerSignature(true);
    }
  }, [isConnected, address]);

  const signWithDiffACcounnt = async () => {
    if (address) {
      try {
        const chainTypeLocal = localStorage.getItem("chainIndex");
        if (chain?.id !== chainId[0]) {
          console.log("message");
          try {
            await switchNetworkAsync(chainId[0]);
          } catch (error) {
            console.log(error, "check");
            return;
          }
        }
        const message = createSiweMessage(
          address,
          "Login with your wallet to 21Spadess",
          chainId
        );
        console.log(message, "message");

        const data = await signMessageAsync({ message });

        console.log(data, "data");

        localStorage.setItem("connectorID", "injected");
        localStorage.setItem("walletAddress", address.toLowerCase());

        let wData = {
          wallet_address: address.toLowerCase(),
          message,
          signature: data,
        };
        // initialise-user with 21Spades
        await apiHelperBlockchain("initialise-user", "POST", wData).then(
          (res) => {
            if (res.data.status === true) {
              localStorage.setItem("accessToken", res?.data?.data?.accessToken);
              localStorage.setItem(
                "userData",
                JSON.stringify(res?.data?.data?.[0])
              );
              if (parseInt(chain?.id) === LocalChainID[1]) {
                localStorage.setItem("chainIndex", 2);
                localStorage.setItem("chainType", "avax");
              } else if (parseInt(chain?.id) === LocalChainID[0]) {
                localStorage.setItem("chainIndex", 1);
                localStorage.setItem("chainType", "avax");
              }
              // window.location = "/" + props.currLocale + "/nft";

              localStorage.setItem("walletAddress", address);
              localStorage.setItem(
                "userData",
                JSON.stringify(res?.data?.data?.[0])
              );
              router.reload(window.location.pathname);
            }
          }
        );
      } catch (error) {}
    }
  };

  const disconnectForDiffAcc = async () => {
    const userdata = localStorage.getItem("userData");
    if (userdata) {
      localStorage.clear();
      await disconnectAsync();
      router.reload();
    }
  };
  // const { Moralis } = useMoralis();
  useEffect(() => {
    const callAsyncFunc = async () => {
      const bal_in_wei = await signer?.getBalance();
      console.log(bal_in_wei);
      const bal = ethers.utils.formatEther(bal_in_wei.toString());
      setBalance(bal);
      console.log(bal, "data");
      localStorage.setItem("accountBalance", bal);
      // const chain = process.env.TYPE === "prod" ? "avax" : chainIdHEX[0]
      // const options = {
      //   method: "GET",
      //   url: `https://deep-index.moralis.io/api/v2/${address}/balance?chain=${chain}`,
      //   headers: {
      //     accept: "application/json",
      //     "X-API-Key":
      //       "ytHvdq0bKzDMNuYnWyyArowhz4tZpqTCEvOZLnOYcLr571h8BSRDvnduSiV94LCO",
      //   },
      // };

      // axios
      //   .request(options)
      //   .then(function (response) {
      //     const bal = response?.data?.balance || 0;
      //     setBalance(bal);
      //     console.log(bal, response,"data");
      //     localStorage.setItem("accountBalance", bal)
      //   })
      //   .catch(function (error) {
      //     console.log(error,"err here");
      //   });
    };
    if (signer) {
      callAsyncFunc();
    }
  }, [chain, isConnected, signer]);

  const { connect, connectors } = useConnect();

  const bal = balance || 0;
  const { t } = useTranslation("common");

  const appInfoEns = {
    imageUrl: `${process.env.hostBaseUrl}/ENSAppIcon.png`,
    name: t("UPYO_ENS"),
    publisher: t("UPYO_inc"),
    infoText: t("play_store"),
    linkButtonText: t("view"),
    linkUrl:
      "https://play.google.com/store/apps/details?id=com.21Spades.name&hl=en_IN&gl=US",
  };

  // // Switch Chains
  // useEffect(() => {
  //   const fromLocalStorage = localStorage.getItem("chain_id")
  //   if (!chainId.includes(chain?.id)) {
  //     // const fromLocalStorage = localStorage.getItem("chain_id")
  //     if (fromLocalStorage) {
  //       switchNetwork?.(parseInt(fromLocalStorage));
  //     } else {
  //       switchNetwork?.(parseInt(chainId[0]));
  //     }
  //   }
  //   const chain_id = localStorage.getItem("chain_id")
  //   if (parseInt(chain?.id) === chainId[1]) {
  //     localStorage.setItem("chainIndex", 2)
  //   } else if (parseInt(chain?.id) === chainId[0]) {
  //     localStorage.setItem("chainIndex", 1)
  //   }
  // }, [isConnected, chain, address])

  const { currentTheme, setCurrentTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    // const themeName = (currentTheme === ConstHelper.LIGHT_THEME ? ConstHelper.DARK_THEME : ConstHelper.LIGHT_THEME)
    const themeName = ConstHelper.DARK_THEME;
    setCurrentTheme(themeName);
    console.log("data", themeName);
    if (typeof document !== "undefined") {
      document.body.setAttribute("data-theme", themeName);
    }
    localStorage.setItem("theme", themeName);
  };
  useEffect(() => {
    const themeName = ConstHelper.DARK_THEME;
    document.body.setAttribute("data-theme", themeName);
    localStorage.setItem("theme", themeName);
  }, []);

  // useEffect(() => {
  //   boot()
  //   if (localStorage.getItem('walletAddress') && localStorage.getItem('walletAddress') !== '') {
  //     let secret = process.env.NEXT_PUBLIC_INTERCOM_ID_SECRET_ID;
  //     let cryptoJS = CryptoJS;
  //     let stringID = (localStorage.getItem('walletAddress')).toString();
  //     // let hash = cryptoJS.HmacSHA256(stringID, secret);
  //     // let hex = cryptoJS.enc.Hex.stringify(hash);
  //     boot({ user_hash: hex })
  //   }
  // })

  let meta = children.props && children.props.meta;
  let canonical = children.props.canonical
    ? children.props.canonical
    : process.env.hostBaseUrl + "/" + router.locale + router.asPath;
  let ogImage =
    children.props.feature_image && children.props.feature_image !== ""
      ? children.props.feature_image
      : process.env.hostBaseUrl +
        "/img/og_image/" +
        (children.props.ogImage
          ? children.props.ogImage
          : "21Spades-default.png");
  let imageExt =
    ogImage && ogImage !== "" ? ogImage.split(".").pop() : "image/webp";
  let ogUrl = canonical;
  let dynamicUrl = children.props.dynamicURL
    ? children.props.dynamicURL
    : router.asPath;
  let isSafari = false;
  if (
    typeof window !== "undefined" &&
    typeof window.navigator !== "undefined"
  ) {
    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  useEffect(() => {
    let url = pathname.split("/");
    setIsNews(url.includes("news") || url.includes("article"));
  }, [pathname]);

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      setToken();
    }

    async function setToken() {
      try {
        const token = await firebaseCloudMessaging.init();
        if (token) {
          const accessToken = localStorage.getItem("accessToken");
          let authentication = null;
          if (accessToken) {
            authentication = { authorization: `Bearer ${accessToken}` };
          }
          await apiHelperBlockchain(
            "notifications/add-fcm",
            "POST",
            { fcm_token: token },
            authentication
          );
        }
      } catch (error) {}
    }
  });

  let equivalentSlug = "";
  if (
    children.props.article &&
    children.props.article.equivalentSlug &&
    children.props.article.equivalentSlug !== ""
  ) {
    equivalentSlug = "/article/" + children.props.article.equivalentSlug;
  } else if (
    children.props.equivalentSlug &&
    children.props.equivalentSlug !== ""
  ) {
    equivalentSlug = children.props.equivalentSlug;
  }

  const isNewsPage = children.props.isNewsPage
    ? children.props.isNewsPage
    : router.asPath &&
      (router.asPath.includes("/news") ||
        router.asPath.includes("/article") ||
        router.asPath.includes("/editor"));
  const page = children.props && children.props.pageNumber;
  const totalNumberOfPages =
    children.props.totalNumberOfPages && children.props.totalNumberOfPages;
  return (
    <>
      <Head>
        {/* <title>{meta && meta.pageTitle ? meta.pageTitle : '21Spades-NFT'}</title> */}
        <title>21Spades - Best DPR Marketplace to Trade DPR</title>
        <meta name="application-name" content="21Spades" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="21Spades" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-tap-highlight" content="no" />

        {isNewsPage && (
          <>
            <link
              rel="alternate"
              type="application/rss+xml"
              title="21Spades News"
              href={`${process.env.hostBaseUrl}/${
                children.props.currLocale ? children.props.currLocale : "ar"
              }/news/feed.xml`}
            />
            {isSafari && router && router.pathname === "/news" && (
              <meta name="apple-itunes-app" content="app-id=1639488983" />
            )}
          </>
        )}
        {!dynamicUrl.includes("/article/") &&
        !dynamicUrl.includes("/events") &&
        router.pathname &&
        router.pathname !== "/news/[categorySlug]" &&
        router.pathname &&
        router.pathname !== "/events/[event]" &&
        router.pathname &&
        router.pathname !== "/events/category/[categorySlug]" &&
        router.pathname &&
        router.pathname !== "/news/tags/[tagSlug]" ? (
          <>
            <link
              rel="alternate"
              hrefLang="en"
              href={process.env.hostBaseUrl + "/en" + dynamicUrl}
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href={process.env.hostBaseUrl + "/en" + dynamicUrl}
            />
            <link
              rel="alternate"
              hrefLang="ar"
              href={process.env.hostBaseUrl + "/ar" + dynamicUrl}
            />
          </>
        ) : equivalentSlug !== "" ? (
          <>
            <link
              rel="alternate"
              hrefLang="en"
              href={
                process.env.hostBaseUrl +
                "/en" +
                (children.props.currLocale === "en"
                  ? dynamicUrl
                  : equivalentSlug)
              }
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href={
                process.env.hostBaseUrl +
                "/en" +
                (children.props.currLocale === "en"
                  ? dynamicUrl
                  : equivalentSlug)
              }
            />
            <link
              rel="alternate"
              hrefLang="ar"
              href={
                process.env.hostBaseUrl +
                "/ar" +
                (children.props.currLocale === "ar"
                  ? dynamicUrl
                  : equivalentSlug)
              }
            />
          </>
        ) : (
          <>
            {/* <link rel="alternate" hrefLang={children.props.currLocale} href={process.env.hostBaseUrl + '/' + children.props.currLocale + dynamicUrl} />
              {children.props.currLocale === 'en' ? <link rel="alternate" hrefLang='x-default' href={process.env.hostBaseUrl + '/en' + dynamicUrl} /> : null} */}
          </>
        )}

        <link
          rel="shortcut icon"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link rel="icon" href={`${process.env.hostBaseUrl}/21.png.png`} />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="48x48"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />

        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="apple-touch-icon"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        {/*<link rel="apple-touch-icon" sizes="114x114" href={`${process.env.hostBaseUrl}/apple-icon-114x114.png`} />*/}
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${process.env.hostBaseUrl}/21.png.png`}
        />
        <link rel="manifest" href="/manifest.json" />

        <meta name="msapplication-TileColor" content="#ffffff" />

        <meta
          name="theme-color"
          content={
            currentTheme === ConstHelper.DARK_THEME ? "#141416" : "#ffffff"
          }
        />

        <link
          rel="mask-icon"
          href={`${process.env.hostBaseUrl}/safari-pinned-tab.svg`}
          color="#5bbad5"
        />
        <meta
          name="msapplication-TileImage"
          content={`${process.env.hostBaseUrl}/ms-icon-144x144.png`}
        />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta
          name="title"
          content={
            meta && meta.pageTitle ? meta && meta.pageTitle : "21Spades-NFT"
          }
        />
        <meta
          name="description"
          content={
            meta && meta.pageMetaDescription ? meta.pageMetaDescription : ""
          }
        />

        {router &&
        router.pathname &&
        (router.pathname === "/404" || router.pathname === "/_error") ? null : (
          <>
            <meta
              name="robots"
              content={
                meta && meta.metaRobots && meta.metaRobots !== ""
                  ? meta.metaRobots
                  : "index,follow"
              }
            />
            <link rel="canonical" href={canonical} />
            <meta
              property="og:type"
              content={`${
                router &&
                router.pathname &&
                router.pathname === "/article/[articleSlug]"
                  ? "article"
                  : "website"
              }`}
            />
            <meta
              property="og:title"
              content={meta && meta.pageTitle ? meta.pageTitle : "21Spades-NFT"}
            />
            <meta
              property="og:description"
              content={
                meta && meta.pageMetaDescription ? meta.pageMetaDescription : ""
              }
            />
            <meta property="og:url" content={ogUrl} />
            <meta property="og:site_name" content="21Spades" />
            {children.props.externalOgImage &&
            children.props.externalOgImage !== "" ? (
              <meta
                property="og:image"
                content={children.props.externalOgImage}
              />
            ) : (
              <meta property="og:image" content={ogImage} />
            )}
            <meta
              property="og:image:alt"
              content={
                meta && meta.metaOgImgAlt && meta.metaOgImgAlt !== ""
                  ? meta.metaOgImgAlt
                  : "NFT Marketplace in Saudi Arabia Social Sharing Image"
              }
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:type" content={"image/" + imageExt} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@UPYOcom" />
            <meta
              name="twitter:description"
              content={
                meta && meta.pageMetaDescription ? meta.pageMetaDescription : ""
              }
            />
            <meta
              name="twitter:title"
              content={meta && meta.pageTitle ? meta.pageTitle : ""}
            />
            {children.props.externalOgImage &&
            children.props.externalOgImage !== "" ? (
              <meta
                property="twitter:image"
                content={children.props.externalOgImage}
              />
            ) : (
              <meta name="twitter:image" content={ogImage} />
            )}
            <meta
              name="twitter:image:alt"
              content={
                meta && meta.metaOgImgAlt && meta.metaOgImgAlt !== ""
                  ? meta.metaOgImgAlt
                  : "NFT Marketplace in Saudi Arabia Social Sharing Image"
              }
            />
          </>
        )}
        {children.props.isENSPage &&
          children.props.isENSPage === true &&
          isSafari && (
            <meta name="apple-itunes-app" content="app-id=1637224142" />
          )}

        {totalNumberOfPages &&
        totalNumberOfPages < 2 &&
        totalNumberOfPages >= 1 ? (
          ""
        ) : page && page === 1 ? (
          <link
            rel="next"
            href={
              process.env.hostBaseUrl +
              (router.asPath.includes("?")
                ? router.asPath.split("?")[0].toString()
                : router.asPath) +
              "?page=2"
            }
          />
        ) : page && page === totalNumberOfPages ? (
          <link
            rel="prev"
            href={
              process.env.hostBaseUrl +
              (router.asPath.includes("?")
                ? router.asPath.split("?")[0].toString()
                : router.asPath) +
              (page
                ? "?page=" + (parseInt(totalNumberOfPages) - 1).toString()
                : "")
            }
          />
        ) : page && page < totalNumberOfPages ? (
          <>
            <link
              rel="prev"
              href={
                process.env.hostBaseUrl +
                (router.asPath.includes("?")
                  ? router.asPath.split("?")[0].toString()
                  : router.asPath) +
                (page && page === 2
                  ? ""
                  : page
                  ? "?page=" + (parseInt(page) - 1).toString()
                  : "")
              }
            />
            <link
              rel="next"
              href={
                process.env.hostBaseUrl +
                (router.asPath.includes("?")
                  ? router.asPath.split("?")[0].toString()
                  : router.asPath) +
                (page ? "?page=" + (parseInt(page) + 1).toString() : "")
              }
            />
          </>
        ) : (
          ""
        )}
      </Head>

      {children.props.isENSPage &&
        children.props.isENSPage === true &&
        !isSafari && (
          <div className="d-block d-sm-none ensBanner">
            <AppBanner
              appInfo={appInfoEns}
              position="top"
              onClose={() => {
                console.log("onClose called");
              }}
              hidden={false}
            />
          </div>
        )}
      {router.pathname !== "/feed" && (
        <Header
          toggleTheme={toggleTheme}
          currLocale={children.props && children.props.currLocale}
          categoryList={children.props && children.props.catList}
          dynamicUrl={dynamicUrl}
          equivalentSlug={
            children.props.equivalentSlug && children.props.equivalentSlug
          }
        />
      )}
      <main id="main" className={isNewsPage ? layoutStyles.newsMain : ""}>
        {children}
      </main>
      {router.pathname !== "/feed" && (
        <Footer
          locale={router.locale}
          currLocale={children.props && children.props.currLocale}
          categoryList={children.props && children.props.catList}
          pageId={children.props.pageId}
          pageName={children.props.pageName}
        />
      )}

      {/* Network change */}
      {!isNews ? (
        <NetworkModal
          networkChangeModal={networkChangeModal}
          chainIndex={2}
          chainID={chain?.id}
        />
      ) : (
        <Fragment />
      )}
      {!isNews && triggerSignature ? (
        <SignWithDiffAccount
          signWithDiffACcounnt={signWithDiffACcounnt}
          disconnectForDiffAcc={disconnectForDiffAcc}
          isSignWithDiffAccount={triggerSignature}
        />
      ) : (
        <Fragment />
      )}
    </>
  );
}
