import React from 'react';

function CopyBidsIocn({className}) {
    return (
        <svg className={className} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8917 2.625L15.775 2.15833V9.68333L17.8 4.8C18.1417 3.95 17.75 2.975 16.8917 2.625ZM0.641692 5.70833L4.77502 15.6667C4.89674 15.9697 5.10435 16.2305 5.37233 16.417C5.64031 16.6036 5.95696 16.7077 6.28336 16.7167C6.50002 16.7167 6.72502 16.675 6.94169 16.5833L13.0834 14.0417C13.7084 13.7833 14.0917 13.1667 14.1084 12.55C14.1167 12.3333 14.075 12.0917 14 11.875L9.83336 1.91667C9.71557 1.61168 9.50871 1.34922 9.23969 1.16343C8.97067 0.977644 8.65196 0.87714 8.32502 0.875C8.10836 0.875 7.89169 0.925 7.68336 1L1.55002 3.54167C1.14233 3.70862 0.817618 4.03065 0.647286 4.43694C0.476953 4.84324 0.474941 5.30055 0.641692 5.70833ZM14.1 2.54167C14.1 2.09964 13.9244 1.67572 13.6119 1.36316C13.2993 1.05059 12.8754 0.875 12.4334 0.875H11.225L14.1 7.825" fill="white" />
        </svg>
    );
}

export default CopyBidsIocn;