// Ethereun ERC721
export const mintableAddress = process.env.TYPE === "prod" ? "0x0dD02e425C61D81607EeAef83E2D3Ac3255c2835" : "0xcD0824cacEc82DfC3Ba601947CEfcd426D52288B";
export const factoryAddress =   process.env.TYPE === "prod" ? "0x4353C471ab6D71FF2769Ce688Ef018870F15346d" : "0xf0DfDCb5716d77D5412E0173B4B0C4cD40D7f3Ed";
export const factoryAddressV2 = process.env.TYPE === "prod" ? "0x4353C471ab6D71FF2769Ce688Ef018870F15346d" : "0xf0DfDCb5716d77D5412E0173B4B0C4cD40D7f3Ed";
export const marketplaceAddress =  process.env.TYPE === "prod" ? "0x659e3818BAD706C313d734acEF948Ef38fb09344" : "0x1cf2d74593776c2195f928c01f0815Fb848B0b30";

// Ethereun ERC1155
export const ERC1155MintableAddress = process.env.TYPE === "prod" ? "0xdff3c5d967c3e60dccf649cbe573f1af7bbc36c8" : "0x8f7a9a1822378880fccf658f6fd24ad6adb25df1";
export const ERC1155FactoryAddress = process.env.TYPE === "prod" ? "0xbbd50add7dcbdb5e22e27c4c530a841072904fbb" : "0x69a9310d185A6354611aa023113B9Cd21193b6dc";
export const ERC1155MarketPlaceAddress =  process.env.TYPE === "prod" ? "0xe020d20ee9d437400d266c1b85e732feda5e66e8" : "0x047886c7531a55685e1153e0df9656874941d1cc";

// Ethereun WETH
export const wethAddress = process.env.TYPE === "prod" ? "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7" : "0x451fc62628A26247d9A02A282DC4cA64C52007c9";

// Ethereum RPC
export const RPC_URL = process.env.TYPE === "prod" ? "https://avalanche.public-rpc.com" : "https://endpoints.omniatech.io/v1/avax/fuji/public";  

// Polygon ERC721
export const mintableAddressPolygon = process.env.TYPE === "prod" ? "0xd8A40d70932006F374C6C0e49f05e3321ef6aDD8" : "0x087FA2dCfe209bec1Eaa4b2a6E390217a36CF02d";
export const factoryAddressPolygon =   process.env.TYPE === "prod" ? "0x2e756f6723c7981f84171e0C3357089762763684" : "0x2DE428140aA0164DcE995565e52e20a451A29Fc3";
export const factoryAddressPolygonV2 =   process.env.TYPE === "prod" ? "0x1cf2d74593776c2195f928c01f0815Fb848B0b30" : "0x629bf0182Be4862691328C6b52634788EaDda45C";
export const marketplaceAddressPolygon =  process.env.TYPE === "prod" ? "0x1dFA1E1ba2D09Fe1C32e3a76178Ef68e36f3Be51" : "0xB34907eFECa5fc20F9493E1431A94D626970040b";

// Polygon ERC1155
export const ERC1155MintableAddressPolygon = process.env.TYPE === "prod" ? "0x26c560d17A1072e50958A5c5000670E0F591A9aa" : "0x9415500F5373E9b02Da2B77BdFA7192D45B6d6c3";
export const ERC1155FactoryAddressPolygon = process.env.TYPE === "prod" ? "0xA9FB799b9a38418308d4Fc7c4204BD081d89C70E" : "0xE6bb39efDd90238778bCc0166324a18b22a9F663";
export const ERC1155MarketPlaceAddressPolygon =  process.env.TYPE === "prod" ? "0x4f87Af224CACAE32c48dc9A7c51a43eA5c88EEC1" : "0xaD054310F86861cB9dFba78363f13137038C5b1b";

// Polygon WETH
export const wethAddressPolygon = process.env.TYPE === "prod" ? "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270" : "0xF6033CA65FF1F83B217d82DBF15D6539D092118C";

// Polygon RPC
export const RPC_URLPolygon = process.env.TYPE === "prod" ? "https://polygon-mainnet.g.alchemy.com/v2/-PtvvNRu2jp50aMrnIYj3vbho2whZdhu" : "https://polygon-mumbai.g.alchemy.com/v2/jzP_jnBpOSBKGLzYGjE-zl9Z1XBPnlii";  

// Other Contract Related
export const MORALIS_SERVER_URL = process.env.TYPE === "prod" ? "https://gtbmqvopuhw4.usemoralis.com:2053/server" : "https://odrbfzsemwxb.usemoralis.com:2053/server";
export const BROKER_ADDRESS = process.env.type === "prod" ?  "0x3c32E23822c594342DacC547A41501C982694e67" : "0x3c32E23822c594342DacC547A41501C982694e67";

// Chain Related
export const chainId = process.env.TYPE === "prod" ? [43114] : [43113];
export const chainIdHEX = process.env.TYPE === "prod" ?  ["0xa86a"]: ["0xa869"];
export const acceptableChainIDs = ["0xa86a","0xa869"]
export const POLYGON_CHAIN_IDS = ["0x89","0x13881"]
export const POLYGON_CHAIN_IDS_Int = [137,80001]
export const ETH_CHAIN_IDS = ["0xa86a","0xa869"]

// Fees
export const UPYO_Fees = 7;

// erc20Token
export const erc20Token = "0x0000000000000000000000000000000000000000";

// token URI
export const tokenURI_prefix = "https://21Spades.mypinata.cloud/ipfs/";

// IPFS
export const ipfsURL = "https://ipfs.infura.io:5001/api/v0";
export const IPFS_projectId = "fdafa7bb64cb4796824ed480a15b2b49";
export const IPFS_projectSecret = "8Av9ZoSD5HGd8aUTewdpcD6+WlMXzy/2HAt2Y0eW5ppBCIgRQRiW7w";

// Pinata
export const pinataLink = "https://gateway.pinata.cloud/ipfs/";

// S3 Bucket Image 
export const s3Link = "https://node-bucket-21nft.s3.amazonaws.com/";
export const s3ImageLink = "https://node-bucket-21nft.s3.amazonaws.com/collectible/";

//public sale collection

export const SaleCollection = process.env.TYPE === "prod" ? "0x4c33827d8bd3dc69b36e97d37b2169ff48e3b6a9" : "0x368A6D13Fe7f258bfc6F440EB813C52Fa5F21088"









