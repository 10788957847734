import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// componenets
import BidsList from "../../home/HotBid/Bidslist";
import { withTranslation } from 'next-i18next';

// Styles
import "../../../node_modules/slick-carousel/slick/slick.css";
import 'react-rangeslider/lib/index.css';

import Select from 'react-select';
import Image from "next/image";

import { withRouter } from 'next/router';

// API helper
import { apiHelperBlockchain } from '../../../helper/apiHelper';

import styles from "./Auction.module.scss";

import NotFoundSection from "../../NotFoundSection";

import { ShimmerSimpleGallery } from "react-shimmer-effects";

class LiveAuctions extends Component {
    state = {

        all_nft_list: [],

        // pagination
        page: 1,
        size: 4,
        clicked_viewMore: true,
        network: { value: 1, label: <><Image src={"/img/Avalanche_logo.png"} alt="avalanche" width={16} height={16} /><span>Avalanche</span></> },
        networkListDropdown: [
            { value: 1, label: <><Image src={"/img/Avalanche_logo.png"} alt='avalanche' width={16} height={16} /><span>Avalanche</span></> },
            // { value: 2, label: <><Image src={nexaImg} alt='nexa' width={16} height={16} /><span>Nexa</span></>},
            // { value: 2, label: <><Image src={nexaImg} alt={this.props.t('common:polygon')} width={16} height={16} /><span>{this.props.t('common:polygon')}</span></> },
        ],
        nftLoader: false
    };

    componentDidMount = () => {
        this.getAllNFTList();
    }

    getAllNFTList = async () => {

        if (this.state.page === 1) { this.setState({ nftLoader: true }); }

        let nPass = {};
        nPass.page = this.state.page;
        nPass.size = this.state.size;
        nPass.chainIndex = this.state.network.value;

        if (localStorage.getItem('userData') && localStorage.getItem('userData') !== "") {
            const user = JSON.parse(localStorage.getItem('userData'));
            nPass.user_id = user._id
        }

        let cList = this.state.all_nft_list;
        let cList1 = [];

        await apiHelperBlockchain('auctions?page=' + this.state.page + "&size=" + this.state.size + "&chainIndex=" + this.state.network.value + "&user_id=" + nPass.user_id,  'GET', nPass).then(resp => {

            this.setState({ nftLoader: false });

            if (resp.data.status === true) {
                cList1 = resp.data.data.nfts;
            }

            if (resp.data.status === false) {
                cList1 = [];
            }

            cList1 = resp.data.status ? resp.data.data.nfts : []
            let fArray = cList.concat(cList1);
            this.setState({ all_nft_list: fArray }, () => {

                // if (fArray.length === resp.data.data.total_nfts) {
                //     this.setState({ clicked_viewMore: false });
                // }
            });

        }).catch(error => console.error(`Error: ${error}`));
    }

    loadMoreNft = () => {

        this.props.router.push('/nft/live-auction/');
        
        // this.setState({
        //     page: this.state.page + 1
        // }, () => {
        //     this.getAllNFTList();
        // });
    }

    handleChangeBlockchain = (opt) => {
        this.setState({ network: opt, page: 1, size: 4, all_nft_list: [], clicked_viewMore : true }, () => {
            this.getAllNFTList();
        });
    };

    refreshMetadata = (db_id, new_item) => {

        let nData = this.state.all_nft_list;
        const indexOfItemInArray = nData.findIndex(q => q._id === db_id);

        if (indexOfItemInArray > -1) {
            nData[indexOfItemInArray].imageUrl = new_item.imageUrl;
        }
    }

    render() {
        const { t } = this.props;

        const { all_nft_list } = this.state;

        return (
            <section className={`section ${styles.discoverIndex2} ${this.props.customClass ? styles.customSection : ''}`}>
                <Container>
                    <Row>
                        <Col sm={6}>{this.props.title ? <h2 className={`h3 ${styles.hot__title}`}>{t('common:live_auctions')} 🔥</h2> : null}</Col>
                        <Col sm={6}>
                            <div className="selectMenu">
                                <Select
                                    inputId="network"
                                    value={this.state.network}
                                    onChange={this.handleChangeBlockchain}
                                    options={this.state.networkListDropdown}
                                    classNamePrefix="select__Inner"
                                    placeholder={t('common:select')}
                                    isSearchable={false}
                                    instanceId="blockchain"
                                    // isDisabled={true}
                                />
                            </div>
                        </Col>
                    </Row>

                    {this.state.nftLoader ?
                        <ShimmerSimpleGallery imageHeight={256} row={1} col={4} gap={20} caption />
                        :
                        all_nft_list && (all_nft_list).length > 0 ?
                            <Row className={styles.nftRows}>
                                {console.log("all_nft_list",all_nft_list)}
                                {all_nft_list.map((info, idx) => { 
                                    return (
                                        <Col xl={3} md={4} xs={6} key={idx}>
                                            <BidsList
                                            
                                                id={info._id}
                                                nftId={info.nft_id}
                                                signature={info.signature}
                                                put_on_sale={info.put_on_sale}
                                                imgURL={(!info.imageUrl || info.imageUrl.length < 1) ? info.imageIPFS : info.imageUrl}
                                                imageIPFS={info.imageIPFS}
                                                is_image_loader={false}
                                                walletAddress={info.walletAddress}
                                                owner={info.owner}
                                                title={info.itemName}
                                                price={info.price}
                                                collection_id={info.collection_id}
                                                auctionType={info.auctionType}
                                                tokenId={info.nft_id}
                                                tokenQty={info.tokenQty}
                                                closingTime={info.closingTime}
                                                highestBidder={info.highestBidder}
                                                currentBid={info.currentBid}
                                                is_lazy_mint={info.is_lazy_mint ? info.is_lazy_mint : false}
                                                royalty={info.royalty}
                                                jsonIpfs={info.jsonIpfs}
                                                user={info.user && info.user}
                                                is_multiple={info.is_multiple && info.is_multiple ? true : false}
                                                ownerList={info.ownerList && info.ownerList}
                                                nftUsdPrice={info.nftUsdPrice && info.nftUsdPrice ? info.nftUsdPrice : 0}
                                                favoriteNFT={info.favoriteNFT && info.favoriteNFT ? info.favoriteNFT : {}}
                                                chainIndex={info.chainIndex && info.chainIndex ? info.chainIndex : 1}
                                                refreshMetadata={this.refreshMetadata}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                            : <NotFoundSection type="no-auctions" />
                    }

                    {(all_nft_list.length < this.state.size) ?
                        null :
                        this.state.page === 1 &&
                            this.state.clicked_viewMore ?
                            <div className="text-center" onClick={this.loadMoreNft}>
                                <div className={`btn buttonStroke loadmoreBtn`}>
                                    {t('common:view_more')}
                                </div>
                            </div> : null
                    }

                </Container>
            </section>
        )
    }
}
export default withTranslation()(withRouter(LiveAuctions));
