import React from 'react';

function ReportsIcon() {
    return (
        <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.000976562V24.001V0.000976562Z" fill="#141416" />
            <path d="M1 0.000976562V24.001" stroke="#141416" strokeWidth="1.4" strokeMiterlimit="10" />
            <path d="M1 2.67285C1 2.67285 3.10938 4.0791 5.21875 4.0791C8.5892 4.0791 10.2858 1.2666 13.6562 1.2666C15.7656 1.2666 17.875 2.67285 17.875 2.67285V13.9229C17.875 13.9229 15.7656 12.5166 13.6562 12.5166C10.2858 12.5166 8.5892 15.3291 5.21875 15.3291C3.10938 15.3291 1 13.9229 1 13.9229" stroke="#141416" strokeWidth="1.4" strokeMiterlimit="10" />
        </svg>
    );
}

export default ReportsIcon;