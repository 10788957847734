import React from 'react';
import styles from "./Header.module.scss";
import Image from "next/image";
import {s3Link} from "../../config/ethereum-config";
import verified from "../../public/img/verify.svg";
import Link from "next/link";
import {useTranslation} from "next-i18next";

function AccountsRowItem({ pro, sendLink }) {
    const { t } = useTranslation("common");
    return (
        <Link href="#">
            <a onClick={() => sendLink("/nft/profiles/" + (pro.customUrl ? pro.customUrl : pro.walletAddress))}>
                <div className={styles.searchList} >
                    <div className="d-flex align-items-center">
                        <Image src={pro.profilePhoto ? s3Link + "profileimage/" + pro.profilePhoto : "/avatar-creator.jpg"}
                               alt="UpYo NFT" width={32} height={32} layout="fixed" />
                        <div className={styles.list}>
                            <div className="d-flex align-items-center">
                                <span className={`me-1 ${styles.name}`}>
                                    {pro.displayName ? pro.displayName : pro.walletAddress.substring(1, 4) + "..." + pro.walletAddress.slice(-4)}</span>
                                {pro.isVerified && <Image src={verified} alt={t('common:all_collections')} width="16" height="16" /> }
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    );
}

export default AccountsRowItem;