import React from 'react';
import styles from "./Header.module.scss";
import { CloseIcon, SearchIcon } from "../icons";
import { DebounceInput } from "react-debounce-input";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import Link from "next/link";
import Image from "next/image";
import verified from "../../public/img/verify.svg";
import SearchImg from "./SearchImg";
import { useTranslation } from "next-i18next";
import CollectionsRowItem from "./CollectionsRowItem";
import AccountsRowItem from "./accountsRowItem";

const SearchResultView = ({ searchResult, sendLink }) => {
    const { t } = useTranslation("common");
    if (searchResult) {
        if (searchResult.accounts.accounts.length === 0
            && searchResult.collections.collections.length === 0
            && searchResult.nfts.nfts.length === 0) {
            return (
                <div className={`${styles.dropdownMenu}`}>
                    <p>{t('common:no_items_found')}</p>
                </div>
            )
        }
        return (
            <div className={`${styles.dropdownMenu}`}>
                {searchResult.collections.collections && searchResult.collections.collections.length > 0 && (
                    <div className={styles.listItem}>
                        <div className={styles.NavSearch}>{t('common:collections')}</div>
                        {
                            searchResult.collections.collections.map(col => (
                                <CollectionsRowItem key={col._id}
                                    col={col}
                                    sendLink={sendLink} />
                            )
                            )
                        }
                    </div>
                )}

                {searchResult.accounts.accounts && searchResult.accounts.accounts.length > 0 && (
                    <div className={styles.listItem}>
                        <div className={styles.NavSearch}>{t('common:accounts')}</div>
                        {
                            searchResult.accounts.accounts.map(pro => (
                                <AccountsRowItem key={pro._id}
                                    sendLink={sendLink}
                                    pro={pro}
                                />
                            )
                            )}
                    </div>
                )}

                {searchResult.nfts.nfts && searchResult.nfts.nfts.length > 0 && (
                    <div className={`${styles.listItem} ${styles.itemList}`}>
                        <div className={styles.NavSearch}>{t('common:items')}</div>
                        {
                            searchResult.nfts.nfts.map(item => (
                                <Link href="#" key={item._id}>
                                    <a onClick={() => sendLink('/nft/tokens/' + (item.chainIndex === 2 ? "avalanche/" : "avalanche/") + item.collection_id + "/" + (item.nft_id ? item.nft_id : item.signature))}>
                                        <div className={styles.searchList}>
                                            <div className="d-flex align-items-center">
                                                <SearchImg src={item.imageUrl} />
                                                <div className={styles.list}>
                                                    <div className="d-flex align-items-center">
                                                        <span className={`me-1 ${styles.name}`}>{item.itemName.substring(0,18)}</span>
                                                    </div>
                                                    {item.creator &&
                                                        <div className="d-flex align-items-center">
                                                            <span className={`me-1`}>{item.creator.displayName ? item.creator.displayName : item.creator.walletAddress.substring(1, 4) + "..." + item.creator.walletAddress.slice(-4)}</span>
                                                            {item.creator.isVerified ? <Image src={verified} alt={t('common:all_collections')} width="16" height="16" /> : null}
                                                        </div>}

                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </Link>
                            )
                            )}
                    </div>

                )}
            </div>
        )
    }
    return null;
}

function SearchOpenHeader({ changeSearch, search, handleClose, isSearchOpen, searchResult, isClose, searchLoader, handleCloseIcon, sendLink }) {
    const { t } = useTranslation("common");
    return (
        <div className={`d-none d-xl-block ${styles.header__search} ${styles.search__mobile}`} id="someElementID">
            <div className={`${styles.header__result} ${styles.icon}`}>
                <div className={`${styles.icon} ${styles.search}`}>
                    <SearchIcon className={styles.searchicon} />
                </div>
                {isClose && (
                    <button type="button" className={styles.close} onClick={handleClose}>
                        <CloseIcon />
                    </button>
                )}
                <DebounceInput
                    debounceTimeout={300}
                    className={`form-control ${styles.header__input}`}
                    type="text"
                    value={search}
                    placeholder={t('common:search_filter')}
                    onChange={changeSearch} />

                {isSearchOpen && (
                    searchLoader ?
                        <div className={`${styles.dropdownMenu}`}>
                            <ShimmerCategoryItem />
                        </div> : <SearchResultView searchResult={searchResult} sendLink={sendLink} />
                )}
            </div>

            <div className={`d-block d-xl-none ${styles.icon} ${styles.closeSearchBar}`} onClick={handleCloseIcon}>
                <CloseIcon className="navbar_expand" />
            </div>
        </div>
    );
}

export default SearchOpenHeader;