import 'firebase/messaging'
import firebase from 'firebase/app'

const initialiseApp = (firebase) =>{
    return firebase?.initializeApp({
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_FIREBASE_authDomain,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_projectId,
        storageBucket: process.env.NEXT_PUBLIC_FIREBASE_storageBucket,
        messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_messagingSenderId,
        appId: process.env.NEXT_PUBLIC_FIREBASE_appId,
        measurementId: process.env.NEXT_PUBLIC_FIREBASE_measurementId
    })
}

const firebaseCloudMessaging = {
    //initializing firebase app
    init: async function () {
      if (!firebase?.apps?.length) {
        
        const firebaseApp = initialiseApp(firebase);
        try {
          const messaging = firebaseApp.messaging()
          //requesting notification permission from browser
          const status = await Notification.requestPermission()
          if (status && status === 'granted') {
            //getting token from FCM
            const fcm_token = await messaging.getToken({
              vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAP_ID
            })
            if (fcm_token) {
              //return the FCM token after saving it
              return fcm_token
            }
          }
        } catch (error) {
          console.error(error,"error")
          return null
        }
      }
    },
    delToken : async () =>{
        if (!firebase?.apps?.length){
            const firebaseApp = initialiseApp(firebase);
            try {
                const messaging = await firebaseApp.messaging().deleteToken();

                return true
            } catch (error) {
                console.error(error,"error")
                return null
            }
        }
        else{
            try {
                const messaging = await firebase.messaging().deleteToken();

                return true
            } catch (error) {
                console.error(error,"error")
                return null
            }
        }
    }
  }
  export { firebaseCloudMessaging }