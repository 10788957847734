import styles from "./Loader.module.scss";

export default function OnlyLoader() {
  return (
      <div className={styles.loader}>
        <div className={styles.loaderBorder}></div>
        <div className={styles.loaderBorder}></div>
        <div className={styles.loaderBorder}></div>
        <div className={styles.loaderBorder}></div>
        <div className={styles.loaderBorder}></div>
        <div className={styles.loaderBorder}></div>
        <div className={styles.loaderBorder}></div>
        <div className={styles.loaderBorder}></div>
      </div>
  )
}
