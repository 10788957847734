import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// componenets
import BidsList from "../../home/HotBid/Bidslist";

// Pugins
import { withTranslation } from 'next-i18next';

// Styles
import "../../../node_modules/slick-carousel/slick/slick.css";
import styles from "./Discover.module.scss";

import NotFoundSection from "../../NotFoundSection";

import { withRouter } from 'next/router';

// API helper
import { apiHelperBlockchain } from '../../../helper/apiHelper';

import { ShimmerSimpleGallery } from "react-shimmer-effects";
import CategoryFilterDesktop from '../../common/categoryFilter/CategoryFilterDesktop';
import {discoverAllNFTList} from "../../../helper/utilityHelper";
class DiscoverSection extends Component {
    state = {
        sliderValue: 25,
        activeKey: 0,
        categoryList: [],
        cList: "",
        category_id: 0,
        all_nft_list: [],
        nftLoader: true,

        // pagination
        page: 1,
        size: 8,

        // Filter part
        network: "",
        type: "",
        creator: "",
        is_creator_verified: false,
        currency: 0,
        min_price: 0,
        max_price: 0
    };

    componentDidMount = () => {
        //  this.getAllCategory();
        this.getAllNFTList();
        this.setState({ all_nft_list: this.props.all_nft_list });
    }

    getAllNFTList = async () => {
        this.setState({ nftLoader: true });
        try{
            const allNftList = await discoverAllNFTList(this.state, 'get-global-nft');
            this.setState({ all_nft_list: allNftList });
        }catch(error){
        }
        this.setState({ nftLoader: false });
    }

    loadMoreNft = () => {
        let cat = "";
        if (this.state.category_id === 0) { }
        else {
            let cItem = this.state.categoryList.find((e) => e._id === this.state.category_id);
            let catName = cItem.category_name.replace(/ /g, "-");
            cat = decodeURI(catName);
        }

        // redirect to dedicated page
        this.props.router.push('/nft/category/' + cat + '?start=1&chain=' + this.state.network + "&type=" + this.state.type + "&creator=" + this.state.creator + "&currency=" + this.state.currency + "&min_price=" + this.state.min_price + "&max_price=" + this.state.max_price);
    }

    // getAllCategory = async () => {
    //     apiHelperBlockchain('category-list', 'GET').then(resp => {
    //         console.log("resp",resp.data)
    //         if (resp.data.status) {

    //             let cList = resp.data.data;
    //             let tCat = [{ value: 0, label: "❇️ " + this.props.t('common:all') }];
    //             cList.forEach(ele => {
    //                 tCat.push({ value: ele._id, label: ele.category_img + " " + (this.props.currLocale === "ar" ? ele.category_name_arabic : ele.category_name) });
    //             });

    //             this.setState({ categoryList: resp.data.data, cList: tCat });
    //         }
    //     }).catch(error => console.error(`Error: ${error}`));
    // }

    itemsChange = (itemsOption) => {
        this.setState({ itemsOption })
    };

    changeCategory = (e) => {
        this.setState({ category_id: e, page: 1, size: 8, all_nft_list: [] }, () => { this.getAllNFTList(); });
    }

    changeNetwork = (item) => {
        this.setState({ network: item, page: 1, size: 8, all_nft_list: [], min_price: "", max_price: "", priceType: 0 }, () => { this.getAllNFTList(); });
    }

    changeType = (item) => {
        this.setState({ type: item, page: 1, size: 8, all_nft_list: [] }, () => { this.getAllNFTList(); });
    }

    changeCreator = (item) => {
        this.setState({ creator: item, page: 1, size: 8, all_nft_list: [] }, () => { this.getAllNFTList(); });
    }

    applyPrice = (min_price, max_price, currency) => {
        this.setState({ currency: currency, min_price: min_price, max_price: max_price, page: 1, size: 8, all_nft_list: [] }, () => { this.getAllNFTList(); });
    }

    refreshMetadata = (db_id, new_item) => {

        let nData = this.state.all_nft_list;
        const indexOfItemInArray = nData.findIndex(q => q._id === db_id);

        if (indexOfItemInArray > -1) {
            nData[indexOfItemInArray].imageUrl = new_item.imageUrl;
        }
    }

    render() {
        const { t } = this.props;

        const { all_nft_list } = this.state;
        return (
            <section className={`section ${styles.discoverIndex2} ${styles.discoverMain} ${this.props.customClass ? styles.customSection : ''}`}>
                <Container className={styles.container}>
                    {this.props.title ? <h2 className={`h3 ${styles.hot__title}`}>{t('common:discover')} ✨</h2> : null}

                    <CategoryFilterDesktop
                        indexFilter={true}
                        categoryList={this.state.categoryList}
                        cList={this.state.cList}
                        currLocale={this.props.currLocale}
                        changeNetwork={this.changeNetwork}
                        changeType={this.changeType}
                        changeCreator={this.changeCreator}
                        changeCategory={this.changeCategory}
                        applyPrice={this.applyPrice}
                        isSortingShow={false}
                        isFire={false}
                    />

                    {this.state.nftLoader ?
                        <ShimmerSimpleGallery imageHeight={256} row={2} col={4} gap={20} caption />
                        :
                        all_nft_list && (all_nft_list).length > 0 ?
                            <>
                                <Row className={styles.nftRows}>
                                    {all_nft_list.map((info, idx) => {
                                        return (
                                            <Col xl={3} md={4} xs={6} key={idx}>
                                                <BidsList
                                                    id={info._id}
                                                    nftId={info.nft_id}
                                                    signature={info.signature}
                                                    put_on_sale={info.put_on_sale}
                                                    imgURL={info.imageUrl}
                                                    imageIPFS={info.imageIPFS}
                                                    title={info.itemName}
                                                    price={info.price && info.price}
                                                    discounted_price={info.onSaleData && info.onSaleData.discounted_price && parseFloat(info.onSaleData.discounted_price) > 0 ? info.onSaleData.discounted_price : ''}
                                                    walletAddress={info.walletAddress}
                                                    owner={info.owner}
                                                    tokenId={info.nft_id}
                                                    tokenQty={info.tokenQty}
                                                    collection_id={info.collection_id}
                                                    auctionType={info.auctionType}
                                                    is_lazy_mint={info.is_lazy_mint ? info.is_lazy_mint : false}
                                                    royalty={info.royalty}
                                                    jsonIpfs={info.jsonIpfs}
                                                    user={info.user && info.user}
                                                    is_multiple={info.is_multiple && info.is_multiple ? true : false}
                                                    ownerList={info.ownerList && info.ownerList}
                                                    nftUsdPrice={info.nftUsdPrice && info.nftUsdPrice ? info.nftUsdPrice : 0}
                                                    favoriteNFT={info.favoriteNFT && info.favoriteNFT ? info.favoriteNFT : {}}
                                                    chainIndex={info.chainIndex && info.chainIndex ? info.chainIndex : 1}
                                                    refreshMetadata={this.refreshMetadata}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </> :
                            <>
                                <NotFoundSection type="nft" />
                            </>}

                    {all_nft_list && (this.state.page === 1 && all_nft_list.length < this.state.size) ?
                        null : all_nft_list && all_nft_list.length > 0 ?
                            <div className="text-center" onClick={this.loadMoreNft}>
                                <div className="btn buttonStroke loadmoreBtn">
                                    {t('common:view_more')}
                                </div>
                            </div> : null}

                </Container>
            </section>
        )
    }
}
export default withTranslation()(withRouter(DiscoverSection));