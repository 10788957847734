import React from 'react';

function CircleIcon() {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.5" cy="12" r="11" stroke="#777E91" strokeWidth="2" />
        </svg>

    );
}

export default CircleIcon;