import React from 'react';

function CloneIcon(props) {
    return (
        <svg onClick={props.onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9881 9.67114C12.0031 9.92065 12.2637 10.0889 12.4754 9.9561C13.7908 9.13082 14.6654 7.66762 14.6654 6.00016C14.6654 3.42283 12.576 1.3335 9.99869 1.3335C8.33123 1.3335 6.86803 2.20803 6.04275 3.52345C5.9099 3.73519 6.07821 3.9957 6.32772 4.0107C9.3116 4.19009 11.8087 6.68983 11.9881 9.67114Z" fill="#3772FF" />
            <path d="M10.6654 10.0002C10.6654 12.5775 8.57603 14.6668 5.9987 14.6668C3.42137 14.6668 1.33203 12.5775 1.33203 10.0002C1.33203 7.42283 3.42137 5.3335 5.9987 5.3335C8.57603 5.3335 10.6654 7.42283 10.6654 10.0002Z" fill="#3772FF" />
        </svg>
    );
}

export default CloneIcon;