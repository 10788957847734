import React, { Component } from 'react';
import { withTranslation } from 'next-i18next';
import "swiper/css/autoplay";

// components
import dynamic from 'next/dynamic';
import TopSellers from './TopSellers';
import HeaderSection from "./HeaderSection";

// Swiper
import "swiper/css";
import "swiper/css/navigation";

const TopCollection = dynamic(() => import('./TopCollection'))
const DiscoverSection = dynamic(() => import('../home/DiscoverSection'))
const LiveAuctions = dynamic(() => import('../home/LiveAuctions'))
const BrowseCategory = dynamic(() => import('./BrowseCategory'))
const CreateNFT = dynamic(() => import('./CreateNFT'))
const SellNFT = dynamic(() => import('./SellNFT'))
const Resource = dynamic(() => import('./Resources'))
const Rewording = dynamic(() => import('./Rewording'))
import Schema from '../../helper/schemaHelper';
class IndexVersionTwo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      AfterSubmitModal: false,
      windowWidth: 0,
      hideDiscountBanner: true
    }
  }

  componentDidMount() {
    if (localStorage.getItem('closeDiscountBanner') && localStorage.getItem('closeDiscountBanner') === true) {
      this.setState({
        hideDiscountBanner: true
      })
    } else {
      this.setState({
        hideDiscountBanner: false
      })
    }
  }

  closeIcon = () => {
    localStorage.setItem('closeDiscountBanner', true);
    this.setState({
      hideDiscountBanner: true
    })
  }

  render() {
    const { orgSchema, websiteSchema } = Schema;

    // "potentialAction": {
    //   "@type": "SearchAction",
    //   "target": {
    //     "@type": "EntryPoint",
    //     "urlTemplate": "${process.env.hostBaseUrl}/nft/search?q=default"
    //   }
    // },
    // "query-input": {
    //   "@type": "PropertyValueSpecification",
    //   "valueRequired": "http://schema.org/True",
    //   "valueName": "search_term_string"
    // }
    const { meta, currLocale, categoryList, faq, resources, currentTheme } = this.props;
    return (
      <>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: orgSchema(meta) }} />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: websiteSchema }} />

        <HeaderSection  currentTheme={currentTheme} currLocale={currLocale} />

        {/* <HotCollection version={this.props.version} /> */}
        <DiscoverSection title={true} currLocale={currLocale} categoryList={categoryList} />
        {/* <PopularSeller  currLocale={this.props.currLocale} /> */}
        {/* <TopSellers currLocale={currLocale} />*/}
        {/* <Seller currLocale={this.props.currLocale} /> */}
        <LiveAuctions title={true} currLocale={this.props.currLocale} />
        <BrowseCategory currLocale={this.props.currLocale} categoryList={this.props.categoryList && this.props.categoryList} />
        <TopCollection />
        {/* <CreateNFT /> */}
        <Rewording/>
        {/* <SellNFT faqData={faq} /> */}
        {/* <Resource resources={resources} currLocale={currLocale} /> */}
        {/* <AppBanner /> */}
      </>
    )
  }
}
export default withTranslation()(IndexVersionTwo);